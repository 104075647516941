@import "src/styles/variables";

.user-mobile-modal-content {
  width            : 100%;
  max-width        : 400px;

  @media only screen and (max-width : 440px) {max-width : calc(100vw - 40px)}
  @media only screen and (max-width : 320px) {max-width : 100vw}
  }

.user {
  display         : flex;
  position        : relative;
  justify-content : center;
  align-items     : center;
  height          : 100%;

  &__switch-button {
    display         : flex;
    justify-content : center;
    align-items     : center;
    width           : 60px;
    max-width       : 40px;
    height          : 60px;
    max-height      : 40px;
    padding         : 0;
    overflow        : hidden;
    transition      : $transitionDefault, outline 0s ease;
    border          : none;
    border-radius   : 50%;
    outline         : 1px solid transparent;
    outline-offset  : -1px;
    background      : none;

    [data-device=mobile] & {
      display         : flex;
      justify-content : center;
      align-items     : center;
      max-width       : 35px;
      max-height      : 35px;
      overflow        : hidden;
      border-radius   : 50%;
      }

    &:hover {outline-color : $colorFontInfo;}

    //&:focus {outline : none}

    &:focus-visible {
      outline-color : $colorFontInfo;
      }

    &--active {
      outline-color : $colorFontInfo;

      &:hover {
        //> img {box-shadow : 2px 2px 10px rgba(0, 0, 0, 0.4)}
        }

      > img {
        //box-shadow : 2px 2px 10px rgba(0, 0, 0, 0.4);

        //&:hover {box-shadow : 2px 2px 10px rgba(0, 0, 0, 0.4)}
        }
      }

    &__no-image, &__image {
      display          : block;
      background-color : $colorFontLight;
      color            : $colorBackgroundBasic;
      }

    &__no-image {
      width      : 100%;
      max-width  : 100%;
      max-height : 100%;
      }

    &__image {
      width      : calc(100% + 20px);
      max-width  : calc(100% + 20px);
      max-height : calc(100% + 20px);
      }
    }
  }

.details {
  display          : flex;
  position         : absolute;
  flex-flow        : column;
  width            : 330px;
  max-height       : calc(100vh - 120px);
  top              : 60px;
  right            : 0;
  overflow-x       : hidden;
  overflow-y       : auto;
  transition       : .2s $transitionTimingFunctionDefault .1s, opacity .1s $transitionTimingFunctionDefault .16s;
  transform        : scale(0.6);
  transform-origin : top right;
  border-radius    : $borderRadiusContainerOuter;
  opacity          : 0;
  visibility       : hidden;
  box-shadow       : 1px 1px 10px rgba(0, 0, 0, .1), 0 0 5px rgba(0, 0, 0, .06);

  &--show {
    transition          : .1s $transitionTimingFunctionDefault .0s, opacity .05s $transitionTimingFunctionDefault .0s;
    transform           : scale(1);
    opacity             : 1;
    visibility          : visible;
    overscroll-behavior : none;

    .user__details__block {
      transition : .1s ease .05s;
      opacity    : 1;
      visibility : visible;
      }
    }
  }