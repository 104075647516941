.draft-link {
  font-family     : inherit;
  font-weight     : inherit;
  text-decoration : underline;
  color           : $colorFontInfo;

  &:visited {color : $colorFontInfo;}

  &__external-icon {
    margin-right   : 0.4rem;
    margin-left    : 0.4rem;
    user-select    : none;
    font-size      : 1.2rem;
    pointer-events : none;
    }
  }