@import "src/styles/variables";

.container {
  display          : flex;
  flex-flow        : column;
  flex-grow        : 1;
  align-items      : center;
  width            : 100%;
  height           : 100%;
  padding          : 0;
  background-color : $colorBackgroundBasicLight;
  }

.block {
  display     : flex;
  flex-flow   : column;
  align-items : center;
  width       : 100%;
  }

.contentCenter {
  display     : flex;
  flex-flow   : column;
  align-items : center;
  width       : 100%;
  max-width   : 120rem;
  }

.contentStretch {
  display     : flex;
  flex-flow   : column;
  align-items : center;
  width       : 100%;
  }