@import "src/styles/variables";

.ft-text {

  &--folded {
    display     : flex;
    align-items : center;
    }

  &--folded &__content {
    //padding-right : 10px;
    overflow      : hidden;
    white-space   : nowrap;
    text-overflow : ellipsis;

    & > * { // usual element
      padding-right : 10px;
      overflow      : hidden;
      white-space   : nowrap;
      text-overflow : ellipsis;

      & > * { // draft js
        display       : none;
        flex-shrink   : 0;
        min-width     : 0;
        max-width     : 100%;
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;

        &:first-child {
          display : block;
          }

        > * {
          display       : none;
          flex-shrink   : 0;
          min-width     : 0;
          max-width     : 100%;
          overflow      : hidden;
          white-space   : nowrap;
          text-overflow : ellipsis;

          &:first-child {
            display : block;
            }
          }
        }
      }
    }

  &__content {
    min-width : 0;
    }

  &__trigger {
    flex-shrink : 0;
    font-family : $fontFamilySecondary;
    font-size   : $fontSizeSecondaryTiny;
    line-height : 1.2;
    }
  }