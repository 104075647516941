@import "plans/Plans";

.payment-type {

  &__block {
    position         : relative;
    padding          : 15px 20px 20px;
    border-radius    : $borderRadiusContainerInnerEdge;
    background-color : $colorBackgroundBasicLight;

    &:first-child {
      margin-bottom : $marginContainerInner;
      border-radius : $borderRadiusContainerInnerEdge $borderRadiusContainerInnerEdge $borderRadiusContainerInner $borderRadiusContainerInner;
      }
    &:last-child {
      border-radius : $borderRadiusContainerInner $borderRadiusContainerInner $borderRadiusContainerInnerEdge $borderRadiusContainerInnerEdge;
      }
    &:only-child {
      margin-bottom : 0;
      border-radius : $borderRadiusContainerInnerEdge;
      }

    &__title {
      display     : flex;
      align-items : center;

      &__text {
        margin      : 0;
        transition  : $transitionDefault;
        font-family : $fontFamilySecondary;
        font-size   : 1.5rem;
        font-weight : $fontWeightSecondaryBold;
        line-height : 1.2;
        text-align  : center;
        color       : $colorFontCommon;
        }

      &__icons {
        display         : flex;
        position        : relative;
        justify-content : center;
        align-items     : center;
        width           : 20px;

        &::before, &__check {
          display         : flex;
          position        : absolute;
          justify-content : center;
          align-items     : center;
          width           : 100%;
          height          : 100%;
          transition      : $transitionDefault;
          }

        &::before {
          content       : "";
          width         : 6px;
          height        : 6px;
          transform     : scale(1);
          border-radius : 50%;
          outline       : 2px solid $colorFontLight;
          opacity       : 1;
          visibility    : visible;
          }

        &__check {
          display          : flex;
          align-items      : center;
          transform        : scale(0.6);
          transform-origin : top center;
          font-size        : 1.2rem;
          opacity          : 0;
          visibility       : hidden;
          }
        }
      }

    &--active {
      .payment-type__block {

        &__title {

          &__text {
            font-weight : $fontWeightSecondaryBold;
            color       : $colorAccentBasic;
            }

          &__icons {

            &::before {
              transform  : scale(0.6);
              opacity    : 0;
              visibility : hidden;
              }

            &__check {
              //width      : 2.5rem;
              transform  : scale(1);
              color      : $colorAccentBasic;
              opacity    : 1;
              visibility : visible;
              }
            }
          }
        }
      }

    &--locked {
      .payment-type__block__title {
        &__text {color : $colorFontLight;}
        &__icons::before {opacity : 0.5;}
        }
      }
    }

  &__single {

    &__price {
      display       : flex;
      position      : relative;
      flex-flow     : row wrap;
      margin-right  : -10px;
      margin-bottom : -10px;
      padding       : 10px 0 0;
      user-select   : none;

      &__item {
        width     : 100%;
        max-width : calc(100% / 3);
        height    : 10rem;
        padding   : 0 10px 10px 0;

        &__container {
          position         : relative;
          width            : 100%;
          height           : 100%;
          cursor           : pointer;
          transition       : $transitionDefault;
          border-radius    : 20px;
          outline-offset   : -1px;
          background-color : $colorBackgroundContent;

          [data-theme=light] & {
            //outline        : 1px solid $colorAccentSoft;
            }

          &::before {
            position         : absolute;
            content          : "";
            width            : 100%;
            height           : 100%;
            top              : 0;
            left             : 0;
            transition       : $transitionDefault;
            border-radius    : 20px;
            background-color : $colorAccentSoft;
            opacity          : 0;
            visibility       : hidden;
            z-index          : 1;
            }

          &:hover {
            &::before {
              opacity    : 1;
              visibility : visible;
              }
            }

          &:active {
            border-color     : transparent;
            background-color : $colorAccentSoft;
            &::before {
              opacity    : 0.6;
              visibility : visible;
              }
            }

          &:active &__content__value,
          &:active &__content__currency {color : $colorFontTitle}

          &__content {
            display         : flex;
            position        : absolute;
            justify-content : center;
            align-items     : center;
            width           : 100%;
            height          : 100%;
            top             : 0;
            left            : 0;
            z-index         : 2;

            &__value,
            &__currency {
              margin      : 0;
              transition  : 0s;
              font-family : $fontFamilyPrimary;
              color       : $colorFontCommon;
              }

            &__value {
              padding-right : 0.2rem;
              font-size     : 2.8rem;
              font-weight   : 500;
              }

            &__currency {
              margin-top   : 1rem;
              padding-left : 0.2rem;
              font-size    : 1.6rem;
              font-weight  : 600;
              }
            }

          &__info {
            display          : flex;
            position         : absolute;
            justify-content  : center;
            align-items      : center;
            max-width        : calc(50% - 0.8rem);
            height           : 2rem;
            padding          : 0.4rem 0.8rem;
            top              : 8px;
            left             : 8px;
            overflow         : hidden;
            transition       : $transitionDefault;
            font-family      : $fontFamilySecondary;
            font-size        : 0.9rem;
            font-weight      : $fontWeightSecondaryBold;
            line-height      : 1;
            white-space      : nowrap;
            text-transform   : uppercase;
            text-overflow    : ellipsis;
            color            : $colorBackgroundContent;
            border-radius    : 10px;
            background-color : $colorAccentBasic;
            z-index          : 3;
            }

          &__supported {
            display   : flex;
            position  : absolute;
            max-width : calc(50% - 0.8rem);
            top       : 8px;
            right     : 8px;
            z-index   : 3;

            &__method {
              display          : flex;
              justify-content  : center;
              align-items      : center;
              width            : 3rem;
              height           : 3rem;
              margin-left      : 4px;
              border-radius    : 50%;
              background-color : $colorBackgroundBasicLight;

              &__icon {
                font-size : 1.5rem;
                color     : var(--method-color, #ffa42e);
                }
              }
            }

          &--active {
            background-color : $colorAccentBasic;

            [data-theme=light] & {
              outline : none;
              }

            &:hover::before {
              background-color : $colorAccentBasic;
              [data-theme=light] & {
                background-color : $colorAccentBasic;
                }
              }

            //&:hover {border : 0.1rem solid $colorAccentBasic;}
            &:active {
              background-color : $colorAccentBasic;
              }

            .payment-type__single__price__item__container {

              &__content {
                &__value,
                &__currency {color : $colorBackgroundContent;}
                &__value {font-weight : 700;}
                }

              &__supported {
                &__method {
                  &__icon {
                    //color : $colorBackgroundContent;
                    }
                  }
                }
              }
            }

          &--disabled {
            background-color : $colorBackgroundContent;

            &:hover {background-color : $colorBackgroundTop;}

            &:active {
              border-color     : transparent;
              background-color : $colorBackgroundTop;
              }
            }

          &--locked {
            border-color     : #e9e9e9;
            background-color : $colorBackgroundBasic;

            &:hover {
              border-color : $colorBackgroundBasic;

              &::before {
                opacity    : 0;
                visibility : hidden;
                }
              }

            &:active {
              background-color : #e9e9e9;

              &::before {
                opacity    : 0;
                visibility : hidden;
                }
              }

            .payment-type__single__price__item__container {

              &__content {
                &__value,
                &__currency {color : #c3c3c3;}
                }

              &__supported {
                &__method {
                  &__icon {color : #c3c3c3;}
                  }
                }
              }
            }
          }
        }

      &__cover {
        display         : flex;
        justify-content : center;
        align-items     : center;
        width           : 100%;
        height          : 100%;
        padding-bottom  : 5px;
        top             : 0;
        left            : 0;

        &__icon {
          display         : flex;
          justify-content : center;
          align-items     : center;
          padding-bottom  : 0.2rem;
          font-size       : 1.8rem;
          color           : $colorFontInfo;
          }

        &__message {
          padding     : 0 10px;
          font-size   : 1.5rem;
          font-weight : 700;
          line-height : 1.5;
          color       : $colorFontInfo;
          }
        }
      }
    }

  &__subscription {

    &__dashboard-note {
      display     : flex;
      padding-top : 10px;

      &__icon-container {
        padding-right : 10px;
        padding-left  : 5px;

        &__icon {
          font-size : 1.4rem;
          color     : $colorFontWarning;
          }
        }

      &__text {
        font-family : $fontFamilySecondary;
        font-size   : 1.4rem;
        font-weight : $fontWeightSecondaryBold;
        color       : $colorFontCommon;

        & > span {
          font-size : inherit;
          color     : $colorFontWarning;
          }
        }
      }

    &__features {
      border-radius : 10px;

      &__title {
        padding     : 10px 15px;
        font-size   : 1.5rem;
        font-weight : 600;
        color       : $colorFontTitle;

        & > span {
          font-size   : inherit;
          font-weight : inherit;
          color       : inherit;
          }
        }

      &__content {
        display   : flex;
        flex-flow : row;

        &__block-ripple {
          width   : calc(100% / 2);
          cursor  : pointer;
          z-index : 1;

          .ripple-layout:before, .ripple-layout__ripple {
            z-index : 1;
            }
          }

        &__block {
          position      : relative;
          width         : 100%;
          padding       : 10px 15px 15px;
          border-radius : 10px;

          &__title {
            margin         : 0;
            padding-bottom : 10px;
            top            : -1rem;
            left           : 1.5rem;
            font-family    : $fontFamilyPrimary;
            font-size      : 1.4rem;
            font-weight    : 600;
            text-transform : uppercase;
            color          : $colorFontCommon;

            & > span {
              font-family : $fontFamilyPrimary;
              font-size   : inherit;
              font-weight : 700;
              color       : $colorFontWarning;
              }

            &__icon {
              padding-left : 10px;
              font-size    : 16px;
              color        : $colorAccentBasic;
              }
            }

          &__list {
            &__item {
              font-size   : 1.4rem;
              font-weight : 600;
              color       : $colorFontInfo;
              }
            }
          }
        }
      }
    }
  }

@media only screen and (max-width : 670px) {
  .payment-type {
    &__single {
      &__price {
        justify-content : flex-start;
        &__item {
          max-width : calc(100% / 2);
          }
        }
      }
    }
  }

@media only screen and (max-width : 440px) {
  .payment-type {

    &__subscription__features__block__title {
      top       : -0.8rem;
      font-size : 1.3rem;
      }

    &__single {
      &__price {
        justify-content : center;
        &__item {
          max-width : calc(100% / 1);
          }
        }
      }
    }
  }