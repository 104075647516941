@keyframes ripple-layout-radius-in {
  from {
    animation-timing-function : cubic-bezier(.44, .44, .19, .68);
    transform                 : translate(var(--ripple-start, 0)) scale(0);
    }
  to {transform : translate(var(--ripple-end, 0)) scale(1)}
  }

@keyframes ripple-layout-opacity-in {
  from {
    animation-timing-function : cubic-bezier(.44, .44, .19, .68);
    opacity                   : 0.4;
    }
  to {opacity : 0.2}
  }


.ripple-layout-container {
  width            : var(--rippleLayoutWidth, auto);
  max-width        : 100%;
  height           : var(--rippleLayoutHeight, auto);
  padding          : var(--rippleLayoutPadding, 0);

  [data-theme=light] & {
    --rippleLayoutColorBasic      : var(--rippleLayoutColorBasicLight, var(--colorAccentBasic));
    --rippleLayoutColorRippleTint : var(--rippleLayoutColorRippleTintLight, var(--rippleLayoutColorBasic));
    --rippleLayoutColorBorder     : var(--rippleLayoutColorBorderLight, var(--rippleLayoutColorRippleTint));
    --rippleLayoutOpacityHover    : var(--rippleLayoutOpacityHoverLight, 0.1);
    }
  [data-theme=dark] & {
    --rippleLayoutColorBasic      : var(--rippleLayoutColorBasicDark, var(--colorAccentBasic));
    --rippleLayoutColorRippleTint : var(--rippleLayoutColorRippleTintDark, var(--rippleLayoutColorBasic));
    --rippleLayoutColorBorder     : var(--rippleLayoutColorBorderDark, var(--rippleLayoutColorRippleTint));
    --rippleLayoutOpacityHover    : var(--rippleLayoutOpacityHoverDark, 0.1);
    }

  $animation-click : ripple-layout-radius-in var(--rippleLayoutAnimationDuration, 500ms), ripple-layout-opacity-in var(--rippleLayoutAnimationDuration, 500ms);

  .ripple-layout {
    display        : flex;
    position       : relative;
    flex-shrink    : 0;
    width          : 100%;
    max-width      : 100%;
    height         : 100%;
    margin         : 0;
    padding        : 0;
    overflow       : hidden;
    user-select    : none;
    transition     : 0s ease;
    color          : inherit;
    border         : none;
    border-radius  : var(--rippleLayoutBorderRadius, 1rem);
    outline-offset : -0.1rem;
    background     : none;
    box-shadow     : none;

    &::before {
      display    : flex;
      position   : absolute;
      content    : "";
      width      : 100%;
      height     : 100%;
      top        : 0;
      left       : 0;
      transition : $transitionDefault;
      opacity    : 0;
      }

    &:hover, &:focus, &:active {background : none;}

    &:hover {
      &::before {
        background-color : var(--rippleLayoutColorRippleTint);
        opacity          : var(--rippleLayoutOpacityHover);
        }
      }

    &:focus-visible {
      outline        : 1px dashed $colorFontTitle;
      outline-offset : -1px;
      }

    &--with-ripple {
      &:hover {
        &::before {
          background-color : transparent;
          opacity          : 0;
          }
        }
      }

    &__ripple {
      position         : absolute;
      content          : "";
      width            : var(--ripple-width, 100%);
      height           : var(--ripple-height, 100%);
      top              : 0;
      left             : 0;
      transform-origin : center center;
      animation        : $animation-click;
      pointer-events   : none;
      border-radius    : 50%;
      background-color : var(--rippleLayoutColorRippleTint);
      opacity          : 0;
      z-index          : 0;

      &--hold {
        transform : translate(var(--ripple-end, 0));
        opacity   : 0.2;
        }
      }

    &--outlined {outline : 0.1rem solid var(--rippleLayoutColorBorder);}
    }

  &--disabled {

    .ripple-layout {
      background : none;
      opacity    : 0.7;
      filter     : grayscale(1);

      &:hover, &:focus, &:active {
        background : none;;
        filter     : grayscale(1);
        }

      &:hover::before {opacity : 0}

      }
    }
  }