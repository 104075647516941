.draft-img-source {
  display   : flex;
  position  : relative;
  flex-flow : column;
  width     : 100%;

  &__link {
    min-width : 30rem;
    }
  }

.draft-img-drop {
  display          : block;
  position         : relative;
  height           : 35rem;
  cursor           : pointer;
  transition       : $transitionDefault;
  color            : $colorAccentBasic;
  border-radius    : 16px;
  outline          : 1px dashed transparent;
  outline-offset   : -1px;
  background-color : $colorBackgroundBasicLight;

  &__text, &__icon {
    display         : flex;
    position        : absolute;
    justify-content : center;
    align-items     : center;
    width           : 100%;
    height          : 100%;
    transition      : $transitionDefault;
    }

  &__text {
    padding-bottom : 18rem;
    font-family    : $fontFamilySecondary;
    font-size      : $fontSizeSecondaryTiny;
    font-weight    : $fontWeightSecondaryBoldExtra;
    text-transform : uppercase;
    color          : $colorFontDisabled;
    visibility     : visible;
    z-index        : 1;
    }

  &__icon {
    font-size : 15rem;
    color     : $colorFontInfo;
    opacity   : 0.1;
    }

  & > input {
    position   : absolute;
    height     : 0;
    margin     : 0;
    opacity    : 0;
    visibility : hidden;
    z-index    : 3;
    }

  &--drag {
    color            : $colorAccentBasic;
    outline          : 1px dashed $colorAccentBasic;
    background-color : $colorBackgroundBasicLight;

    .draft-img-drop {
      &__text {
        color : $colorAccentLight;
        }
      &__icon {
        color   : $colorAccentBasic;
        opacity : 0.2;
        }
      }
    }

  &:hover, &--drop-over {
    color            : $colorAccentBasic;
    outline          : 1px dashed $colorAccentContrast;
    background-color : $colorBackgroundBasic;

    .draft-img-drop {
      &__text {
        opacity    : 0;
        visibility : hidden;
        }
      &__icon {
        color   : $colorAccentBasic;
        opacity : 0.6;
        }
      }
    }
  }