.draft-ctrl-button {
  padding-right : 0.2rem;

  &__button {
    display          : flex;
    justify-content  : center;
    align-items      : center;
    width            : 3rem;
    height           : 3rem;
    padding          : 0;
    transition       : $transitionDefault;
    border           : none;
    border-radius    : 50%;
    background-color : transparent;

    &:hover {
      background-color : $colorBackgroundBasicLight;
      }

    &:active {
      background-color : $colorBackgroundBasic;
      }

    &__icon {
      display         : flex;
      justify-content : center;
      align-items     : center;
      width           : 1.8rem;
      height          : 1.8rem;
      color           : var(--ctrl-icon-color, $colorFontCommon)
      }

    &__text {
      padding-bottom : 3px;
      font-family    : $fontFamilyPrimary;
      font-size      : 1.3rem;
      font-weight    : $fontWeightPrimaryRegular;
      }

    &--active {
      background-color : $colorBackgroundBasic;

      &:hover {
        background-color : $colorBackgroundBasic;
        }
      }
    }

  &__bold,
  &__thin,
  &__italic,
  &__underline,
  &__code {
    .draft-ctrl-button__button__text {text-transform : uppercase;}
    }

  &__bold .draft-ctrl-button__button__text {font-weight : 700}
  &__thin {
    .draft-ctrl-button__button__text {
      font-weight : lighter;
      opacity     : 0.8;
      }
    }
  &__italic .draft-ctrl-button__button__text {font-style : italic}
  &__underline .draft-ctrl-button__button__text {text-decoration : underline}

  }