.payment-type-plans {
  padding-top : 3rem;
  overflow    : hidden;

  &__layout {
    display       : flex;
    margin-right  : -10px;
    margin-bottom : -10px;
    transition    : $transitionDefault;
    }

  &__plan-container {
    width   : calc(100% / 3);
    padding : 0 10px 10px 0;
    }

  &__plan {
    display          : flex;
    position         : relative;
    flex-flow        : column;
    height           : 100%;
    padding          : 15px 20px;
    cursor           : pointer;
    user-select      : none;
    transition       : $transitionDefault;
    border-radius    : 20px;
    outline-offset   : -1px;
    background-color : $colorBackgroundContent;
    [data-theme=light] & {
      //outline        : 1px solid $colorAccentSoft;
      }

    &:hover {
      background-color : $colorAccentSoft;
      }

    &__star {
      position  : absolute;
      top       : 3px;
      right     : 8px;
      font-size : 1.8rem;
      color     : $colorFontTitle;
      }

    &__status {
      position    : absolute;
      top         : -20px;
      left        : 20px;
      font-family : $fontFamilySecondary;
      font-size   : 1.3rem;
      font-weight : $fontWeightSecondaryRegular;
      color       : $colorFontLight;
      }

    &__name {
      margin         : 0;
      padding-bottom : 1rem;
      font-family    : $fontFamilySecondary;
      font-size      : 1.3rem;
      font-weight    : $fontWeightSecondaryBoldExtra;
      color          : $colorFontCommon;
      }


    &__limits {
      padding : 0.5rem 0;

      &__limit {
        display     : flex;
        align-items : center;

        &__title,
        &__value {
          margin      : 0;
          font-family : $fontFamilySecondary;
          font-size   : 1.3rem;
          line-height : 1.6;
          color       : $colorFontCommon;
          }

        &__title {
          flex-grow     : 1;
          padding-right : 1rem;
          overflow      : hidden;
          font-weight   : $fontWeightSecondaryBold;
          white-space   : nowrap;
          text-overflow : ellipsis;
          }

        &__value {
          flex-shrink : 0;
          font-weight : 600;

          & > span {
            font-size   : 2rem;
            font-weight : $fontWeightSecondaryBoldExtra;
            line-height : 0.5;
            }
          }
        }
      }

    &__includes {

      &__feature {
        display     : flex;
        align-items : center;

        &__status {
          display         : flex;
          flex-shrink     : 0;
          justify-content : flex-start;
          align-items     : center;
          width           : 2.6rem;
          padding-left    : 0.4rem;
          font-size       : 0.9rem;
          color           : $colorFontLight;
          opacity         : 0;
          visibility      : hidden;
          }

        &__title {
          margin        : 0;
          overflow      : hidden;
          font-family   : $fontFamilySecondary;
          font-size     : 1.3rem;
          font-weight   : $fontWeightSecondaryRegular;
          line-height   : 1.6;
          white-space   : nowrap;
          text-overflow : ellipsis;
          color         : $colorFontCommon;
          opacity       : 0.7;
          }

        &--available {
          .payment-type-plans__plan__includes__feature {
            &__status {
              opacity    : 1;
              visibility : visible;
              }

            &__title {
              font-weight : $fontWeightSecondaryBold;
              color       : $colorFontCommon;
              opacity     : 1;
              }
            }
          }
        }
      }
    }
  }

.payment-type-plans__plan--unavailable {
  background-color : $colorBackgroundBasic;
  [data-theme=light] & {
    outline : none;
    }

  &:hover {
    background-color : $colorBackgroundBasic;
    }

  .payment-type-plans__plan {

    &__prices {
      &__price {
        &__button {
          > div {
            color : $colorBackgroundTop;
            [data-theme=light] & {color : $colorBackgroundBasic;}
            > p {
              color : $colorBackgroundTop;
              [data-theme=light] & {color : $colorBackgroundBasic;}
              }
            }
          }
        }

      &--selected-plan {
        .payment-type-plans__plan__prices {
          &__price {
            &__button {
              > div {
                color : $colorBackgroundTop;
                [data-theme=light] & {color : $colorBackgroundBasic;}
                > p {
                  color : $colorBackgroundTop;
                  [data-theme=light] & {color : $colorBackgroundBasic;}
                  }
                }
              }
            }
          }
        }
      }

    &__name,
    &__limits__limit__title,
    &__limits__limit__value,
    &__limits__limit__value > span,
    &__includes__feature__title,
    &__includes__feature__status {
      opacity: 0.1;
      }

    &__cover {
      display         : flex;
      position        : absolute;
      flex-flow       : column;
      justify-content : flex-start;
      align-items     : center;
      width           : 100%;
      max-height      : calc(100% - 6rem);
      padding         : 8px;
      bottom          : 0;
      left            : 0;
      overflow        : auto;
      transition      : $transitionDefault;
      border-radius   : 16px;
      z-index         : 10;


      &::before {
        content     : "";
        flex-shrink : 0;
        width       : 100%;
        height      : 40px;
        bottom      : 100%;
        background  : $colorBackgroundBasicLight;
        background  : linear-gradient(0deg, $colorBackgroundBasicLight 0%, rgba(0, 0, 0, 0) 100%);
        }

      &__content {
        display          : flex;
        position         : relative;
        padding-right    : 8px;
        padding-bottom   : 12px;
        border-radius    : 0 0 16px 16px;
        background-color : $colorBackgroundBasicLight;

        &__icon {
          display         : flex;
          flex-shrink     : 0;
          justify-content : center;
          width           : 30px;
          padding-top     : 1px;
          //padding-right : 7px;
          font-size       : 1.3rem;
          color           : $colorFontInfo;
          }

        &__message {
          font-family : $fontFamilySecondary;
          font-size   : 1.3rem;
          font-weight : $fontWeightSecondaryBold;
          line-height : 1.3;
          color       : $colorFontCommon;
          }
        }
      }
    }
  }

.payment-type-plans__plan--active {
  background-color : $colorAccentBasic;
  [data-theme=light] & {outline : none}

  &:hover, &:active {background-color : $colorAccentBasic;}

  .payment-type-plans__plan {

    &__name,
    &__limits__limit__title,
    &__limits__limit__value,
    &__limits__limit__value > span,
    &__includes__feature__title,
    &__includes__feature__status {
      color : $colorBackgroundContent;
      }
    }
  }

.payment-plans__prices {
  display   : flex;
  flex-flow : column;
  flex-grow : 1;
  padding   : 1.5rem 0;

  &__button-container {
    &__button {}
    &--locked {
      .ripple-button__title { text-decoration : line-through; }
      }
    }
  }

@media only screen and (max-width : 900px) {
  .payment-type-plans {
    &__plan {
      padding : 1rem 1rem 2rem;
      &__includes {
        &__feature {
          &__status {
            width : 1.8rem;
            }
          }
        }
      }
    }
  }

@media only screen and (max-width : 670px) {
  .payment-type-plans {
    flex-flow : row wrap;
    &__plan-container {
      width          : calc(100% / 2);
      padding-bottom : 3rem;
      }
    &__plan {
      padding : 1.5rem 1.5rem 2rem;
      &__includes {
        &__feature {
          &__status {
            width : 3.5rem;
            }
          }
        }
      }
    }
  }

@media only screen and (max-width : 580px) {
  .payment-type-plans {
    &__plan {
      padding : 1rem 1rem 2rem;
      &__includes {
        &__feature {
          &__status {
            width : 1.8rem;
            }
          }
        }
      }
    }
  }

@media only screen and (max-width : 440px) {
  .payment-type-plans {
    &__plan-container {
      width : calc(100% / 1);
      }

    &__plan {
      padding : 1.5rem 1.5rem 2rem;
      &__includes {
        &__feature {
          &__status {
            width : 2.6rem;
            }
          }
        }
      }
    }
  }