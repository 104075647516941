.draft-button-block {
  display         : flex;
  position        : relative;
  justify-content : center;
  align-items     : center;

  &:hover .image-draft-block__edit-button {
    user-select      : none;
    transform        : scale(1);
    transform-origin : right top;
    opacity          : 1;
    visibility       : visible;
    }

  &__link {

    &:hover {text-decoration : none;}
    &:focus-visible {
      outline        : 2px solid $colorFontContrast;
      outline-offset : 2px;
      }

    &__button {
      &:active::before {
        opacity : 0.3 !important;
        }
      }
    }
  }

.draft-custom-edit-button {
  position : absolute;
  top      : 5px;
  right    : -50px;

  &__container {
    display          : flex;
    position         : relative;
    justify-content  : center;
    align-items      : center;
    width            : 40px;
    height           : 40px;
    cursor           : pointer;
    user-select      : none;
    border-radius    : 50%;
    background-color : $colorAccentBasic;
    box-shadow       : -0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.2), -0.1rem -0.1rem 1.2rem rgba(0, 0, 0, 0.12);

    &:hover {
      background-color : $colorAccentLight;
      }

    &__icon {
      user-select : none;
      font-size   : 20px;
      color       : $colorBackgroundContent;
      }

    &__cover {
      position      : absolute;
      width         : 100%;
      height        : 100%;
      top           : 0;
      left          : 0;
      border-radius : 50%;
      z-index       : 1;
      }
    }
  }