.block-messages {
  min-width : 0;
  padding   : var(--block-messages-padding, 0);

  &__message {
    min-width      : 0;
    display        : flex;
    padding-bottom : 10px;

    &:last-child {padding-bottom : 0}

    &__icon, &__text {color : var(--block-message-color, $colorFontError);}

    &__icon {
      flex-shrink     : 0;
      display         : flex;
      justify-content : center;
      width           : 13px;
      padding-top     : 2px;
      font-size       : 1.1rem;
      }

    &__text {
      padding-left : 5px;
      min-width    : 0;
      white-space  : normal;
      font-family  : $fontFamilySecondary;
      font-size    : $fontSizeSecondarySmall;
      font-weight  : $fontWeightSecondaryBold;
      line-height  : $lineHeightSecondarySmall;
      }

    &--warning {--block-message-color : var(--colorFontWarning);}
    &--info {--block-message-color : var(--colorFontInfo);}
    &--success {--block-message-color : var(--colorFontSuccess);}
    }
  }