.cfi {
  &__container {
    &:focus + label {color : var(--input-color-accent, $colorAccentBasic) !important;}
    //.special-label {display : none}
    &:focus + label,
    & + .input-custom__field-container__label--upper {
      height           : auto !important;
      padding          : 0 0.5rem !important;
      top              : -0.7rem !important;
      left             : calc(var(--input-height, 4rem) - 0.5rem);
      font-size        : 1.3rem !important;
      font-weight      : 600 !important;
      pointer-events   : initial !important;
      color            : var(--input-color-font, $colorFontCommon) !important;
      background-color : var(--input-color-background) !important;
      opacity          : 1 !important;
      visibility       : visible !important;
      z-index          : 2;
      }

    .flag-dropdown {
      width  : calc(5rem - 4px) !important;
      height : calc(5rem - 4px) !important;
      margin : 0 !important;
      top    : 2px !important;
      left   : 2px !important;
      }
    }
  &__field {
    width            : 100% !important;
    height           : var(--input-height, 5rem) !important;
    margin           : 0 !important;
    padding          : 0 2rem 0 var(--input-height, 5rem) !important;
    overflow         : hidden !important;
    font-family      : inherit !important;
    font-size        : 1.5rem !important;
    font-weight      : 600 !important;
    text-transform   : initial !important;
    text-overflow    : ellipsis !important;
    color            : var(--input-color-font, var(--colorFontCommon, #585858)) !important;
    //border           : 0.1rem solid var(--input-color-border) !important;
    border           : none !important;
    border-radius    : var(--input-border-radius, 1.6rem) !important;
    background-color : var(--input-color-background) !important;


    &:hover {
      border-color : var(--input-color-border-hover) !important;
      }

    &:focus,
    &:focus-visible {
      border-radius  : calc(var(--input-border-radius, 1.6rem) + 0.1rem) !important;
      outline        : 0.2rem solid var(--input-color-accent, $colorAccentBasic) !important;
      outline-offset : -0.2rem !important;
      }
    }

  &__button {
    width            : calc(var(--input-height, 4rem) - 0.4rem) !important;
    height           : calc(var(--input-height, 4rem) - 0.4rem) !important;
    margin           : 2px 0 2px 2px !important;
    border           : none !important;
    border-radius    : 50% !important;
    background-color : transparent !important;

    .selected-flag {
      display          : flex !important;
      justify-content  : center !important;
      align-items      : center !important;
      width            : 100% !important;
      height           : 100% !important;
      padding          : 0 !important;
      border-radius    : 50% !important;
      background-color : var(--input-color-background) !important;
      z-index          : 2 !important;

      &:hover, &:focus {
        cursor           : initial;
        border           : none !important;
        background-color : var(--input-color-background) !important;
        }
      }
    }
  &__dropdown {}
  &__search {}
  }