@import "../../../../../styles/variables";

.socials {
  display          : flex;
  justify-content  : center;
  align-items      : center;
  align-self       : center;
  min-width        : 132px;
  max-width        : 100%;
  height           : 100%;
  max-height       : 52px;
  padding          : $paddingContainerOuter;
  border-radius    : 2.6rem;
  background-color : $colorBackgroundBasic;

  [data-theme=dark][data-device=desktop] & {
    background-color : $colorBackgroundTop;
    }


  &__title {
    padding     : 20px 0 15px 28px;
    left        : 0;
    font-family : $fontFamilySecondary;
    font-size   : 1.5rem;
    font-weight : $fontWeightSecondaryBold;
    color       : $colorFontTitle;
    }

  [data-device=mobile] & {
    flex-flow        : column;
    flex-grow        : 0;
    align-items      : stretch;
    width            : 100%;
    height           : auto;
    max-height       : 100%;
    padding          : 0;
    background-color : transparent;
    }


  [data-device=desktop] &:hover &__links, [data-device=desktop] &:focus-within &__links {
    &::after {
      max-height : 80px;
      padding    : 35px $paddingContainerOuter 20px $paddingContainerOuter;
      transition : $transitionDefault, color $transitionDefault $transitionDurationDefault;
      color      : $colorFontTitle;
      }
    }

  &__links {
    display         : flex;
    justify-content : center;
    align-items     : center;
    min-width       : 0;

    [data-device=mobile] & {
      flex-flow        : column;
      flex-grow        : 0;
      align-items      : stretch;
      width            : calc(100% - var(--paddingContainerOuter));
      margin           : 0 $paddingContainerOuter 0 0;
      padding          : $paddingContainerOuter 0;
      overflow         : hidden;
      border-radius    : 0 $borderRadiusContainerInnerEdge $borderRadiusContainerInnerEdge 0;
      background-color : $colorBackgroundContent;
      }
    [data-device=mobile][data-theme=dark] & {background-color : $colorBackgroundTop;}

    [data-device=desktop] & {
      position : relative;

      &::after {
        position         : absolute;
        content          : 'Contact ● Get Help ● Discuss';
        width            : calc(100% + (var(--paddingContainerOuter) * 2));
        max-height       : 0;
        padding          : 0;
        top              : 50%;
        transition       : $transitionDefault $transitionDurationDefault, color $transitionDefault 0ms;
        font-family      : $fontFamilySecondary;
        font-size        : 1.5rem;
        font-weight      : $fontWeightSecondaryBoldExtra;
        text-align       : center;
        text-transform   : uppercase;
        color            : transparent;
        border-radius    : 0 0 $borderRadiusContainerInnerEdge $borderRadiusContainerInnerEdge;
        background-color : $colorBackgroundBasic;
        }
      }

    [data-theme=dark] &::after {background-color : $colorBackgroundTop;}
    }
  }

.link {
  display          : flex;
  flex-shrink      : 0;
  justify-content  : center;
  align-items      : center;
  width            : 36px;
  height           : 36px;
  margin-right     : 4px;
  padding          : 2px 0;
  transition       : $transitionDefault, background-color 0s ease;
  border-radius    : 1.8rem;
  outline          : 1px solid transparent;
  outline-offset   : -1px;
  background-color : $colorBackgroundContent;
  z-index          : 2;

  &:last-child {margin : 0;}

  &:hover, &:focus-visible {
    text-decoration  : none;
    border-radius    : 50%;
    background-color : var(--social-color);
    [data-theme=dark] & {background-color : var(--social-color-dark, var(--social-color));}
    }

  &:hover &__logo, &:focus-visible &__logo {
    color : $colorBackgroundContent;
    [data-theme=dark] & {color : $colorBackgroundContent;}
    }

  &__logo {
    margin     : var(--social-margin);
    transition : $transitionDefault;
    font-size  : 21px;
    width  : 21px;
    color      : var(--social-color);
    [data-theme=dark] & {color : var(--social-color-dark, var(--social-color));}
    }
  }

.link--phone {
  flex-shrink : 1;
  width       : auto;
  min-width   : 0;
  padding     : 2px 12px 2px 12px;

  @media (max-width : 960px) {
    flex-shrink : 0;
    width       : 36px;
    padding     : 2px 0;
    }

  &:hover, &:focus-visible {border-radius : 1.8rem;}
  &:hover .link__text, &:focus-visible .link__text {color : $colorBackgroundTop;}

  .link {

    &__text {
      min-width     : 0;
      overflow      : hidden;
      font-size     : 13px;
      font-weight   : 700;
      white-space   : nowrap;
      text-overflow : ellipsis;
      color         : var(--social-color);

      @media (max-width : 960px) {display : none;}
      }

    &__logo {
      margin-right : 3px;
      padding-left : 10px;
      font-size    : 1.6rem;

      @media (max-width : 960px) {
        margin       : 0;
        padding-left : 0;
        }
      }
    }
  }

.linkMobile {
  z-index : 2;

  &:hover {text-decoration : none;}

  &__button {
    & :global .ripple-button {
      &__title, &__icon {color : inherit !important;}
      }
    }
  }