.video-draft-block {
  position    : relative;
  width       : 100%;
  padding-top : var(--aspect-ratio, 56.25%);

  &:hover .image-draft-block__edit-button {
	transform        : scale(1);
	transform-origin : right top;
	opacity          : 1;
	visibility       : visible;
	}

  &__overlay {
	position   : absolute;
	top        : 0;
	right      : 0;
	bottom     : 0;
	left       : 0;

	&__preview {
	  width      : 100%;
	  height     : 100%;
	  }
	}

  &--thin {
	.video-draft-block {
	  &__overlay {
		border : 0.1rem solid #888888;
		}
	  }
	}

  &--full {
	width            : calc(100% - 2rem);
	margin           : 0 2rem 2rem 0;
	padding-top      : calc(var(--aspect-ratio, 56.25%) - 1rem);
	background-color : #2a3a45;

	.video-draft-block {
	  &__overlay {
		margin : 2rem -2rem -2rem 2rem;
		border : 0.1rem solid #2a3a45;
		}
	  }
	}
  }