
.animated-loading {
  z-index              : 100;

  @keyframes animated-loading-appear {
    0% {opacity : 0;}
    100% {opacity : 1;}
    }

  @keyframes animated-loading-circle {
    0% {transform : rotate(0deg);}
    100% {transform : rotate(360deg);}
    }

  $translate-positive  : calc(var(--circle-diameter, 5rem) / 4);
  $translate-negative  : calc(0rem - var(--circle-diameter, 5rem) / 4);

  @keyframes animated-loading-action-top {
    0% {opacity : 1; transform : translateY(0px) scale(1);}
    48% {opacity : 0; transform : translateY($translate-negative) scale(0.9);}
    50% {opacity : 0; transform : translateY($translate-positive) scale(0.9);}
    70% {opacity : 1; transform : translateY(0px) scale(1);}
    }

  @keyframes animated-loading-action-bottom {
    0% {opacity : 1; transform : translateY(0px) scale(1);}
    48% {opacity : 0; transform : translateY($translate-positive) scale(0.9);}
    50% {opacity : 0; transform : translateY($translate-negative) scale(0.9);}
    70% {opacity : 1; transform : translateY(0px) scale(1);}
    }

  @keyframes animated-loading-action-left {
    0% {opacity : 1; transform : translateX(0px) scale(1);}
    48% {opacity : 0; transform : translateX($translate-negative) scale(0.9);}
    50% {opacity : 0; transform : translateX($translate-positive) scale(0.9);}
    70% {opacity : 1; transform : translateX(0px) scale(1);}
    }

  @keyframes animated-loading-action-right {
    0% {opacity : 1; transform : translateX(0px) scale(1);}
    48% {opacity : 0; transform : translateX($translate-positive) scale(0.9);}
    50% {opacity : 0; transform : translateX($translate-negative) scale(0.9);}
    70% {opacity : 1; transform : translateX(0px) scale(1);}
    }

  --cover-color        : var(--colorBackgroundContent);
  --loading-font-color : var(--colorFontCommon);
  --loadingColorCircle : var(--colorAccentBasic);

  [data-theme=dark] & {
    //--cover-color        : #202020;
    //--loading-font-color : #d2d2d2;
    }

  &__content {
    display         : flex;
    flex-flow       : var(--content-flow, column) nowrap;
    justify-content : center;
    align-items     : center;
    z-index         : 2;

    &__ring {
      display       : inline-block;
      position      : relative;
      width         : var(--circle-diameter, 5rem);
      height        : var(--circle-diameter, 5rem);
      overflow      : hidden;
      animation     : animated-loading-appear 300ms cubic-bezier(0.5, 0, 0.5, 1) forwards;
      border-radius : 50%;
      opacity       : 0;
      z-index       : 2;

      & > div {
        display       : block;
        position      : absolute;
        width         : var(--circle-diameter, 5rem);
        height        : var(--circle-diameter, 5rem);
        margin        : 0;
        box-sizing    : border-box;
        animation     : animated-loading-circle 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border        : calc(var(--circle-diameter, 5rem) / 10) solid;
        border-color  : var(--loadingColorCircle) transparent transparent transparent;
        border-radius : 50%;

        &:nth-child(1) {animation-delay : -0.45s;}
        &:nth-child(2) {animation-delay : -0.3s;}
        &:nth-child(3) {animation-delay : -0.15s;}
        }

      &__action {
        display         : flex;
        position        : absolute;
        justify-content : center;
        align-items     : center;
        width           : 100%;
        height          : 100%;
        top             : 0;
        left            : 0;
        font-size       : calc(var(--circle-diameter, 5rem) / 2 - var(--circle-diameter, 5rem) / 30);
        color           : var(--loadingColorCircle);
        z-index         : 2;
        }

      .animated-loading-action-top {
        animation : animated-loading-action-top 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        }

      .animated-loading-action-bottom {
        animation : animated-loading-action-bottom 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        }

      .animated-loading-action-left {
        animation : animated-loading-action-left 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        }

      .animated-loading-action-right {
        animation : animated-loading-action-right 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        }
      }

    &__message {
      animation   : animated-loading-appear 300ms cubic-bezier(0.5, 0, 0.5, 1) forwards;
      font-family : $fontFamilyPrimary;
      font-weight : 700;
      color       : var(--loading-font-color);
      z-index     : 2;
      }
    }

  &__cover {
    position         : absolute;
    content          : "";
    width            : 100%;
    height           : 100%;
    top              : 0;
    left             : 0;
    background-color : var(--cover-color, #fff);
    opacity          : var(--cover-opacity, 0.9);
    z-index          : 1;
    }
  }

.animated-loading-component-cover {
  display         : flex;
  position        : absolute;
  justify-content : center;
  align-items     : center;
  width           : 100%;
  height          : 100%;
  top             : 0;
  right           : 0;
  bottom          : 0;
  left            : 0;
  overflow        : hidden;
  border-radius   : var(--cover-border-radius, 3rem);
  }

.animated-loading-component {
  position : relative;
  }

.animated-loading-component-cover,
.animated-loading-component {
  .animated-loading__content {
    &__ring {margin : 1.5rem;}
    &__message {font-size : calc(var(--circle-diameter, 5rem) / 2.5);}
    }
  }

.animated-loading-page-cover {
  display         : flex;
  position        : fixed;
  justify-content : center;
  align-items     : center;
  width           : 100vw;
  height          : 100vh;
  top             : 0;
  left            : 0;
  }

.animated-loading-page {
  display         : flex;
  position        : relative;
  justify-content : center;
  align-items     : center;
  min-width       : 100%;
  min-height      : 60vh;
  }

.animated-loading-page-cover,
.animated-loading-page {
  .animated-loading {
    &__content {
      position : absolute;
      &__ring {margin : 2rem;}
      &__message {font-size : calc(var(--circle-diameter, 5rem) / 4);}
      }
    }
  }