.questionnaire {
  display    : flex;
  position   : relative;
  flex-flow  : column;
  min-height : 30rem;
  padding    : 0 0 0 20px;

  &__content {
    max-height    : calc(100vh - 350px);
    margin-bottom : 4rem;
    padding       : 0 20px 0 0;
    overflow      : auto;

    &__field {
      padding-left : 43px;
      }
    }

  &__submit {
    align-self : flex-end;
    margin     : 0 20px 20px;
    }
  }