@keyframes ripple-radius-in {
  from {
    animation-timing-function : cubic-bezier(.44, .44, .19, .68);
    transform                 : translate(var(--ripple-start, 0)) scale(0);
    }
  to {transform : translate(var(--ripple-end, 0)) scale(1)}
  }

@keyframes ripple-opacity-in {
  from {
    animation-timing-function : cubic-bezier(.44, .44, .19, .68);
    opacity                   : 0.4;
    }
  to {opacity : 0.2}
  }


.ripple-button-container {
  width                      : var(--buttonWidth, auto);
  max-width                  : 100%;
  height                     : var(--buttonHeight, 3.6rem);
  margin                     : var(--buttonMargin, 0);
  padding                    : var(--buttonPadding, 0);

  --buttonHeight             : 3.6rem;
  --buttonWidth              : auto;

  [data-theme=light] & {
    --buttonColorBasic                    : var(--buttonColorBasicLight, var(--colorAccentBasic));
    --buttonColorBasicNotContained        : var(--buttonColorBasicNotContainedLight, none);
    --buttonColorFont                     : var(--buttonColorFontLight, var(--colorBackgroundContent));
    --buttonColorFontNotContained         : var(--buttonColorFontNotContainedLight, var(--buttonColorBasic));
    --buttonColorBasicDisabled            : var(--buttonColorBasicDisabledLight, #e2e2e2);
    --buttonColorFontDisabled             : var(--buttonColorFontDisabledLight, #b1b1b1);
    --buttonColorFontNotContainedDisabled : var(--buttonColorFontNotContainedDisabledLight, #b1b1b1);
    --buttonColorRippleTint               : var(--buttonColorRippleTintLight, #fff);
    --buttonOpacityHover                  : var(--buttonOpacityHoverLight, 0.1);
    }
  [data-theme=dark] & {
    --buttonColorBasic                    : var(--buttonColorBasicDark, var(--colorAccentBasic));
    --buttonColorBasicNotContained        : var(--buttonColorBasicNotContainedDark, none);
    --buttonColorFont                     : var(--buttonColorFontDark, var(--colorBackgroundContent));
    --buttonColorFontNotContained         : var(--buttonColorFontNotContainedDark, var(--buttonColorBasic));
    --buttonColorBasicDisabled            : var(--buttonColorBasicDisabledDark, #262626);
    --buttonColorFontDisabled             : var(--buttonColorFontDisabledDark, #383838);
    --buttonColorFontNotContainedDisabled : var(--buttonColorFontNotContainedDisabledDark, #383838);
    --buttonColorRippleTint               : var(--buttonColorRippleTintDark, #fff);
    --buttonOpacityHover                  : var(--buttonOpacityHoverDark, 0.1);
    }

  $animation-click-contained : ripple-radius-in var(--buttonAnimationDuration, 500ms), ripple-opacity-in var(--buttonAnimationDuration, 500ms);

  .ripple-button {
    display          : flex;
    position         : relative;
    justify-content  : center;
    align-items      : center;
    width            : 100%;
    max-width        : 100%;
    height           : 100%;
    max-height       : 100%;
    margin           : 0;
    padding          : 0 calc(var(--buttonHeight) / 4);
    overflow         : hidden;
    user-select      : none;
    transition       : 0s ease;
    text-decoration  : none;
    color            : var(--buttonColorFont);
    border           : none;
    border-radius    : var(--buttonBorderRadius, calc(var(--buttonHeight) / 2));
    outline          : none;
    outline-offset   : -0.1rem;
    background-color : var(--buttonColorBasic);
    box-shadow       : none;

    &::before {
      display    : flex;
      position   : absolute;
      content    : "";
      width      : 100%;
      height     : 100%;
      top        : 0;
      left       : 0;
      transition : $transitionDefault;
      opacity    : 0;
      }

    &:hover, &:focus {
      text-decoration  : none;
      background-color : var(--buttonColorBasic);

      &__title, &__icon {
        text-decoration : none;
        }
      }

    &:hover {
      &::before {
        background-color : var(--buttonColorRippleTint);
        opacity          : var(--buttonOpacityHover);
        }
      }

    &:focus-visible {
      outline        : 2px solid $colorFontTitle;
      outline-offset : 0;
      }

    &--with-ripple {
      &:hover {
        &::before {
          background-color : transparent;
          opacity          : 0;
          }
        }
      }

    &__ripple {
      position         : absolute;
      content          : "";
      width            : var(--ripple-width, 100%);
      height           : var(--ripple-height, 100%);
      top              : 0;
      left             : 0;
      transform-origin : center center;
      animation        : $animation-click-contained;
      pointer-events   : none;
      border-radius    : 50%;
      background-color : var(--buttonColorRippleTint);
      opacity          : 0;
      z-index          : 0;

      &--hold {
        transform : translate(var(--ripple-end, 0));
        opacity   : 0.2;
        }
      }

    &__icon {
      display         : flex;
      flex-shrink     : 0;
      justify-content : center;
      align-items     : center;
      width           : calc(var(--buttonHeight) / 2);
      height          : calc(var(--buttonHeight) / 2);
      font-size       : var(--buttonFontSizeIcon, calc(var(--buttonHeight) / 2 - 0.2rem));
      text-decoration : none;
      z-index         : 1;

      &__svg {
        width : var(--buttonFontSizeIcon, calc(var(--buttonHeight) / 2 - 0.2rem));
        }
      }

    &__icon-left {padding-left : calc(var(--buttonHeight) / 9)}
    &__icon-right {
      order         : 2;
      padding-right : calc(var(--buttonHeight) / 9);
      }

    &__title {
      flex-grow       : 1;
      flex-shrink     : 1;
      margin          : 0;
      padding         : 0 calc(var(--buttonHeight) / 4);
      overflow        : hidden;
      transition      : 0s ease;
      font-family     : $fontFamilyPrimary;
      font-size       : var(--buttonFontSizeText, calc(var(--buttonHeight) / 2 - 0.4rem));
      font-weight     : 700;
      line-height     : 1.4;
      white-space     : nowrap;
      text-decoration : none;
      //text-transform  : uppercase;
      text-overflow   : ellipsis;
      color           : inherit;
      z-index         : 1;
      }

    &--outlined,
    &--text {
      color      : var(--buttonColorFontNotContained, var(--buttonColorBasic));
      background : var(--buttonColorBasicNotContained, none);

      &:hover,
      &:focus,
      &:active {background : var(--buttonColorBasicNotContained, none)}

      &:hover::before {background-color : var(--buttonColorBasic)}

      .ripple-button__ripple {background-color : var(--buttonColorBasic)}
      }

    &--outlined {outline : 0.1rem solid var(--buttonColorBasic);}
    }

  &--disabled {

    .ripple-button {
      color            : var(--buttonColorFontDisabled);
      background-color : var(--buttonColorBasicDisabled);

      &:hover,
      &:focus,
      &:active {
        color            : var(--buttonColorFontDisabled);
        background-color : var(--buttonColorBasicDisabled);
        }
      &:hover::before {opacity : 0}

      &--outlined,
      &--text {
        color      : var(--buttonColorFontNotContainedDisabled);
        background : none;
        &:hover,
        &:focus,
        &:active {
          color      : var(--buttonColorFontNotContainedDisabled);
          background : none;;
          }
        }

      &--outlined {
        outline : 0.1rem solid var(--buttonColorFontNotContainedDisabled);
        }
      }
    }
  }