@import '../../../styles/variables';

.cookies {
  display         : flex;
  position        : fixed;
  justify-content : center;
  align-items     : center;
  width           : 100%;
  padding         : 0 1rem;
  bottom          : 3.6rem;
  left            : 0;
  pointer-events  : none;
  z-index         : 990;


  @media only screen and (max-width : 900px) {
    padding : 0;
    bottom  : 0;
    }

  &__content {
    display             : flex;
    flex-flow           : column;
    align-items         : center;
    width               : 100%;
    min-width           : 0;
    max-width           : 90rem;
    max-height          : calc(100vh - 100px);
    padding             : $paddingContainerOuter;
    overflow            : auto;
    pointer-events      : auto;
    border-radius       : $borderRadiusContainerOuter;
    background-color    : $colorAccentBasic;
    box-shadow          : 0 0 0.8rem rgba(0, 0, 0, .1), 0 0.2rem 3rem rgba(0, 0, 0, .15);
    overscroll-behavior : none;

    @media only screen and (max-width : 900px) {
      border-radius : $borderRadiusContainerOuter $borderRadiusContainerOuter 0 0;
      }

    @media only screen and (max-width : 340px) {
      //border-radius : 1.5rem 1.5rem 0 0;
      //box-shadow    : 0 -1rem 2rem rgba(0, 0, 0, .15), 0 -1rem 3rem rgba(0, 0, 0, .20);
      }

    &__container {
      min-width        : 0;
      max-width        : 100%;
      padding          : 20px;
      border-radius    : $borderRadiusContainerInnerEdge;
      background-color : $colorBackgroundTop;

      //@media only screen and (max-width : 340px) {border-radius : 1.5rem 1.5rem 0 0}
      }
    }
  }

.bottom {
  display         : flex;
  justify-content : flex-end;
  padding-top     : 10px;
  }


.text {
  padding     : 1.5rem 2rem 2rem;
  font-family : $fontFamilySecondary;
  font-size   : $fontSizeSecondary;
  font-weight : $fontWeightSecondaryBold;
  line-height : 1.2;
  color       : $colorBackgroundTop;

  //@media only screen and (max-width : 340px) {padding : 5rem 2rem 5rem}

  & > a {
    font-weight     : 700;
    text-decoration : underline;
    color           : inherit;
    }
  }

.switch {
  min-width      : 0;
  padding-bottom : 10px;
  }