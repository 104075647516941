@import 'src/styles/variables';

.dev-logs {
  padding : 1rem 0;

  &__item {
    display   : flex;
    flex-flow : column;

    &--unfold {
      .dev-logs__item {
        &__minimal {
          &__state {
            transform : rotate(90deg);
            }
          &__message {
            color : $colorFontTitle;
            }
          }
        &__data {
          max-height : 30rem;
          padding    : 1rem 1.5rem 1rem 1.8rem;
          overflow   : auto;
          transition : max-height $transitionDefault, padding 0s ease 0s;
          }
        }
      }

    &__minimal {
      display     : flex;
      align-items : center;
      width       : 100%;
      height      : 5rem;
      cursor      : pointer;
      user-select : none;
      transition  : $transitionDefault;

      :global .ripple-layout {
        display     : flex;
        align-items : center;
        padding     : 0 1.5rem;
        }

      &__time {
        margin       : 0;
        padding-left : 0.5rem;
        font-family  : $fontFamilyPrimary;
        font-size    : 1.1rem;
        font-weight  : 700;
        line-height  : 1.4;
        color        : var(--dev-logs-color, #585858);
        }

      &__state {
        display         : flex;
        flex-shrink     : 0;
        justify-content : center;
        align-items     : center;
        width           : 1.4rem;
        height          : 1.4rem;
        transition      : $transitionDefault;
        font-size       : 1.2rem;
        color           : $colorFontLight;
        }

      &__message {
        flex-grow     : 1;
        flex-shrink   : 1;
        margin        : 0;
        padding-left  : 0.5rem;
        overflow      : hidden;
        font-family   : $fontFamilyPrimary;
        font-size     : 1.3rem;
        font-weight   : 600;
        line-height   : 1.4;
        white-space   : nowrap;
        text-overflow : ellipsis;
        color         : $colorFontCommon;
        }
      }

    &__data {
      display          : flex;
      flex-flow        : column;
      flex-grow        : 1;
      max-width        : 100%;
      max-height       : 0;
      padding          : 0 1.5rem 0 1.8rem;
      overflow         : hidden;
      transition       : max-height $transitionDefault, padding 0s ease $transitionDurationDefault;
      background-color : $colorBackgroundBasic;
      [data-theme=dark] & {background-color : $colorBackgroundContent;}

      &__container {
        display       : flex;
        flex-flow     : row wrap;
        align-items   : center;
        margin-bottom : 7px;
        font-size     : 1.3rem;
        line-height   : 1.2;

        &__title {
          flex-grow     : 1;
          margin        : 0;
          overflow      : hidden;
          font-weight   : 700;
          text-overflow : ellipsis;
          color         : $colorFontLight;
          }

        &__value {
          margin        : 0 0 0 10px;
          overflow      : hidden;
          font-weight   : 700;
          text-overflow : ellipsis;
          color         : $colorFontCommon;
          }

        &__icon {
          align-self  : flex-start;
          width       : 16px;
          padding-top : 3px;
          & > i {
            color : var(--dev-logs-error-color, $colorFontWarning);
            }
          }

        &__message {
          width        : calc(100% - 16px);
          padding-left : 7px;
          font-size    : 1.5rem;
          font-weight  : 600;
          color        : var(--dev-logs-error-color, $colorFontWarning);
          }
        }
      }
    }
  }