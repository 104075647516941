.popover {
  display   : flex;
  font-size : 16px;

  &:hover .popover__message {
	opacity    : 1;
	visibility : visible;
	cursor     : pointer;
	}

  &__hint {
	margin-left : 7px;
	color       : #337ab7;
	cursor      : pointer !important;
	}

  &__message {
	display          : flex;
	flex-direction   : column;
	position         : absolute;
	width            : 100%;
	margin           : 0;
	padding          : 18px 22px;
	bottom           : calc(105%);
	left             : 10px;
	background-color : #fff;
	opacity          : 0;
	border           : 1px solid rgba(60, 133, 209, 0.52);
	border-radius    : 3px;
	font-size        : 15px;
	font-weight      : 300;
	line-height      : 24px;
	color            : initial;
	text-transform   : initial;
	z-index          : 997;
	visibility       : hidden;
	box-shadow       : 2px 2px 14px 2px rgba(0, 0, 0, 0.16);
	transition       : $transitionDefault;

	&::after, &::before {
	  position       : absolute;
	  width          : 0;
	  height         : 0;
	  right          : 7px;
	  border         : 10px solid transparent;
	  content        : " ";
	  pointer-events : none;
	  }

	&::after {
	  bottom           : -18px;
	  border-top-color : #fff;
	  }

	&::before {
	  bottom           : -20px;
	  border-top-color : rgba(60, 133, 209, 0.52);
	  }

	&__readMore {
	  width      : 20%;
	  align-self : flex-end;

	  @media screen and (max-width : 500px) {
		width : 40%;
		}
	  }
	}
  }