.range-slider {
  display         : flex;
  align-items     : center;
  //min-height      : 50px;
  --slider-height : 10px;
  --step-width    : 1px;

  &__value {
    flex-grow      : 1;
    min-width      : var(--rs-min-width-value, initial);
    padding-bottom : 10px;
    padding-left   : 10px;
    font-size      : 1.5rem;
    font-weight    : 700;
    text-align     : center;
    white-space    : nowrap;
    text-overflow  : ellipsis;
    color          : $colorAccentBasic;

    & > span {
      font-weight : inherit;
      color       : $colorFontCommon;
      }
    }

  &__slider {
    display   : flex;
    position  : relative;
    flex-flow : column;
    flex-grow : 1;
    width     : calc(100% - var(--slider-height));
    margin    : 0.65rem calc(var(--slider-height) / 2);
    cursor    : pointer;

    &:hover &__point {
      transform : scale(1);
      [data-theme=light] & {background-color : $colorAccentBasic;}
      [data-theme=dark] & {background-color : $colorAccentBasic;}
      }

    &:hover &__fill {
      [data-theme=light] & {background-color : $colorAccentLight;}
      [data-theme=dark] & {background-color : $colorAccentLight;}
      &::before, &::after {
        [data-theme=light] & {background-color : $colorAccentLight;}
        [data-theme=dark] & {background-color : $colorAccentLight;}
        }
      }

    &__base,
    &__fill {
      &::before, &::after {
        position : absolute;
        content  : "";
        width    : var(--slider-height);
        height   : var(--slider-height);
        }

      &::before {
        right         : calc(100% - var(--slider-height) / 2);
        border-radius : calc(var(--slider-height) / 2);
        }

      &::after {
        left          : calc(100% - var(--slider-height) / 2);
        border-radius : calc(var(--slider-height) / 2);
        }
      }

    &__base {
      position : relative;
      content  : "";
      width    : 100%;
      height   : var(--slider-height);
      [data-theme=light] & {background-color : #dcdcdc;}
      [data-theme=dark] & {background-color : #424242;}

      &::before, &::after {
        [data-theme=light] & {background-color : #dcdcdc;}
        [data-theme=dark] & {background-color : #424242;}
        }
      }

    &__fill {
      position   : absolute;
      content    : "";
      width      : var(--fill-width, 0%);
      height     : var(--slider-height);
      top        : 0;
      left       : var(--fill-start, 0%);
      transition : $transitionTimingFunctionDefaultInverse 100ms, left 0s, width 0s;
      [data-theme=light] & {background-color : $colorAccentBasic;}
      [data-theme=dark] & {background-color : $colorAccentBasic;}

      &::before, &::after {
        transition : $transitionTimingFunctionDefaultInverse 100ms;
        [data-theme=light] & {background-color : $colorAccentBasic;}
        [data-theme=dark] & {background-color : $colorAccentBasic;}
        }
      }

    &__point {
      position      : absolute;
      content       : "";
      width         : calc(var(--slider-height) * 2);
      height        : calc(var(--slider-height) * 2);
      top           : calc(0px - var(--slider-height) / 2);
      left          : calc(var(--point-position) - var(--slider-height));
      transition    : $transitionTimingFunctionDefaultInverse 100ms, left 0s;
      transform     : scale(0.5);
      border-radius : 50%;
      [data-theme=light] & {background-color : $colorAccentBasic;}
      [data-theme=dark] & {background-color : $colorAccentBasic;}
      }

    &__steps {
      position : relative;
      width    : calc(100% - var(--step-width));
      height   : 10px;

      &__step {
        position : absolute;
        content  : "";
        width    : var(--step-width);
        height   : 6px;
        bottom   : 0;
        left     : var(--step-position, 0);
        [data-theme=light] & {background-color : #dcdcdc;}
        [data-theme=dark] & {background-color : #424242;}
        }
      }
    }
  }

.range-slider--disabled {

  [data-theme=light] & {--rs-disabled-bg : #aeaeae;}
  [data-theme=dark] & {--rs-disabled-bg : #5d5d5d;}

  .range-slider {
    &__value {
      color : var(--rs-disabled-bg);
      & > span {color : inherit}
      }
    &__slider {
      cursor : initial;
      &:hover {
        .range-slider__slider__point {
          transform        : scale(0.5);
          background-color : var(--rs-disabled-bg);
          }
        .range-slider__slider__fill {
          background-color : var(--rs-disabled-bg);
          &::before, &::after {background-color : var(--rs-disabled-bg);}
          }
        }
      &__fill {
        background-color : var(--rs-disabled-bg);
        &::before, &::after {background-color : var(--rs-disabled-bg);}
        }
      &__point {background-color : var(--rs-disabled-bg);}
      }
    }
  }