@keyframes icon-dev-spin {
  from {transform : rotate(0)}
  to {transform : rotate(180deg)}
  }

.common-stack-icon {
  display         : flex;
  position        : relative;
  justify-content : center;
  align-items     : center;
  width           : 100%;
  max-width       : 100%;
  height          : 100%;
  max-height      : 100%;

  &:hover .common-stack-icon__dev,
  &:hover .common-stack-icon__not-free {
    margin-top : -0.5rem;
    box-shadow : 0 0.2rem 0.8rem rgba(0, 0, 0, 0.2), 0 0 1.6rem rgba(0, 0, 0, 0.1);
    }

  &:hover .common-stack-icon__dev__icon {
    animation : icon-dev-spin 700ms ease-out forwards;
    }

  &__img {
    display   : block;
    flex-grow : 1;
    width     : 100%;
    max-width: 100%;
    max-height: 100%;
    }

  &__not-free,
  &__dev,
  &__formula {
    display          : flex;
    position         : absolute;
    justify-content  : center;
    align-items      : center;
    width            : 3rem;
    height           : 3rem;
    top              : -1.2rem;
    transition       : $transitionDefault, background-color 0s ease;
    color            : $colorFontWarning;
    border-radius    : 50%;
    background-color : $colorBackgroundTop;
    box-shadow       : 0 0.1rem 0.4rem rgba(0, 0, 0, 0.2), 0 0 0.8rem rgba(0, 0, 0, 0.1);
    }

  &__dev {
    right : -0.8rem;

    &__icon {
      font-size : 1.6rem;
      }
    }

  &__not-free {
    right : -0.8rem;

    &__icon {
      font-size : 1.4rem;
      }

    &--second {
      right : 2.6rem;
      }
    }

  &__formula {
    left : -0.8rem;
    &__icon {

      }
    }
  }