// ===  BASIC CONTAINERS AND VISUALS  ===
.input-custom {
  position                           : relative;
  padding                            : 0.5rem 0 0.5rem;
  font-family                        : $fontFamilyPrimary;

  // default vars for input, can be overwritten by passing visualVariables prop to <Input/>
  [data-theme=dark] & {
    //--input-color-background            : #202020;
    --input-color-background            : #141414;
    --input-color-background-hint_block : #3d3d3d;
    --input-color-icon                  : #585858;
    --input-color-border                : #585858;
    --input-color-border-hover          : #7c7c7c;
    --input-color-font-label            : #6b6b6b;
    --input-color-font-label-upper      : #7e7e7e;
    --input-color-font-hint_block       : #838383;
    }
  [data-theme=light] & {
    //--input-color-background            : #fff;
    --input-color-background            : #eaf1fb;
    --input-color-background-hint_block : #efefef;
    --input-color-icon                  : #abb7cc;
    --input-color-border                : #bcbcbc;
    --input-color-border-hover          : #6e6e6e;
    --input-color-font-label            : #879dbb;
    --input-color-font-label-upper      : #879dbb;
    --input-color-font-hint_block       : #8e95a1;
    }
  // variables
  $input-height                      : var(--input-height, 5rem);
  $input-color-background            : var(--input-color-background);
  $input-color-background-hint_block : var(--input-color-background-hint_block);
  $input-color-accent                : var(--input-color-accent, $colorAccentBasic);
  $input-color-icon                  : var(--input-color-icon);
  $input-color-icon-password         : var(--input-color-icon-password, $input-color-accent);
  $input-color-border                : var(--input-color-border);
  $input-color-border-hover          : var(--input-color-border-hover);
  $input-color-font                  : var(--input-color-font, $colorFontTitle);
  $input-color-font-label            : var(--input-color-font-label);
  $input-color-font-label-upper      : var(--input-color-font-label-upper);
  $input-color-font-hint_block       : var(--input-color-font-hint_block);
  //$input-border-radius               : var(--input-border-radius, 25px);
  $input-border-radius               : var(--input-border-radius, 16px);

  &__field-container {
    display     : flex;
    position    : relative;
    font-family : inherit;

    &--left-icon {
      & > .input-custom__field-container {
        &__label {left : $input-height;}
        &__field {
          padding-left : $input-height;
          &:focus + label,
          & + .input-custom__field-container__label--upper {left : calc(var(--input-height, 5rem));}
          }
        }
      }

    &--right-icon & > .input-custom__field-container__field {padding-right : $input-height;}

    &__icon-left,
    &__icon-right,
    &__icon-password-eye {
      display         : flex;
      position        : absolute;
      justify-content : center;
      align-items     : center;
      width           : calc(var(--input-height, 5rem) - 4px);
      height          : calc(var(--input-height, 5rem) - 4px);
      top             : 2px;
      font-size       : calc(var(--input-height, 5rem) / 2.8);
      color           : $input-color-icon;
      opacity         : 1;
      }

    &__icon-left,
    &__icon-right {
      [data-type=error] & {color: $colorFontError;}
      [data-type=warning] & {color: $colorFontWarning;}
      [data-type=success] & {color: $colorFontSuccess;}
      //[data-type=info] & {color: $colorFontInfo;}
      [data-disabled=true] & {color: $colorFontDisabled;}
      }

    &__icon-right,
    &__icon-password-eye {
      right         : 2px;
      border-radius : 50%;
      }

    &__icon-left {
      left          : 2px;
      border-radius : 50%;
      }

    &__icon-right {}

    &__icon-password-eye {
      cursor : pointer;
      color  : $input-color-icon-password;
      }

    &__field {
      width            : 100%;
      height           : $input-height;
      margin           : 0;
      padding          : 0 calc(var(--input-height, 5rem) / 2) 0;
      overflow         : hidden;
      font-family      : inherit;
      font-size        : 1.5rem;
      font-weight      : 600;
      text-transform   : initial;
      text-overflow    : ellipsis;
      color            : $input-color-font;
      border           : 0 solid $input-color-border;
      border-radius    : $input-border-radius;
      outline          : 1px solid transparent;
      outline-offset   : -1px;
      background-color : $input-color-background;

      [data-type=error] & {background-color: $colorBackgroundError;}
      [data-type=warning] & {background-color: $colorBackgroundWarning;}
      [data-type=success] & {background-color: $colorBackgroundSuccess;}
      //[data-type=info] & {background-color: $colorBackgroundInfo;}
      [data-theme=light] [data-disabled=true] & {background-color: #e2e2e2}
      [data-theme=dark] [data-disabled=true] & {background-color : #262626}

      &:hover {
        border-color : $input-color-border-hover;
        }

      &:focus,
      &:focus-visible {
        //border-radius  : calc(var(--input-border-radius, 1rem) + 0.1rem);
        outline        : 2px solid $input-color-accent;
        outline-offset : -2px;
        }

      &:hover, &:focus, &:focus-visible {
        [data-type=error] & {outline-color: $colorFontError;}
        [data-type=warning] & {outline-color: $colorFontWarning;}
        [data-type=success] & {outline-color: $colorFontSuccess;}
        //[data-type=info] & {outline-color: $colorFontInfo;}
        [data-disabled=true] & {outline: none;}
        }

      &:focus + label,
      & + .input-custom__field-container__label--upper {
        height         : auto;
        //padding          : 0 0.5rem;
        //top              : -0.7rem;
        top            : 3px;
        //left             : 1.5rem;
        left           : calc(var(--input-height, 5rem) / 2);
        font-size      : $fontSizeSecondaryTiny;
        font-weight    : $fontWeightSecondaryBold;
        pointer-events : initial;
        color          : $input-color-font-label-upper;
        //background-color : $input-color-background;
        opacity        : 1;
        visibility     : visible;
        }

      &:focus + label {
        color : $input-color-accent;

        [data-type=error] & {color: $colorFontError;}
        [data-type=warning] & {color: $colorFontWarning;}
        [data-type=success] & {color: $colorFontSuccess;}
        //[data-type=info] & {color: $colorFontInfo;}
        [data-disabled=true] & {color: $colorFontDisabled;}
        }
      }

    &--textarea {
      ::-webkit-scrollbar {width : 0.4rem}
      ::-webkit-scrollbar-track {
        margin     : 1rem 0;
        background : $input-color-background;
        }
      ::-webkit-scrollbar-thumb {
        border-radius : 0.2rem;
        background    : #bcbcbc;
        }
      ::-webkit-scrollbar-thumb:hover {background : #848484}
      }

    &__textarea {
      min-width           : var(--min-width-textarea, 100%);
      max-width           : var(--max-width-textarea, 100%);
      min-height          : var(--min-height-textarea, 15rem);
      max-height          : var(--max-height-textarea, 40rem);
      padding-top         : 1rem;
      padding-bottom      : 1rem;
      overflow            : auto;
      line-height         : 1.6;
      overscroll-behavior : none;
      }

    &__label {
      display        : flex;
      position       : absolute;
      align-items    : center;
      max-width      : calc(100% - var(--input-height, 5rem) * 2);
      //height         : $input-height;
      height         : calc(var(--input-height, 5rem) - 4px);
      top            : 2px;
      //left           : 2rem;
      left           : calc(var(--input-height, 5rem) / 2);
      overflow       : hidden;
      transition     : background-color 0s, 50ms $transitionTimingFunctionDefault;
      font-family    : $fontFamilySecondary;
      font-size      : $fontSizeSecondary;
      font-weight    : $fontWeightSecondaryBold;
      line-height    : 1.2;
      white-space    : nowrap;
      text-transform : initial;
      text-overflow  : ellipsis;
      pointer-events : none;
      color          : $input-color-font-label;
      border-radius  : 0.3rem;

      [data-type=error] & {color: $colorFontError;}
      [data-type=warning] & {color: $colorFontWarning;}
      [data-type=success] & {color: $colorFontSuccess;}
      //[data-type=info] & {color: $colorFontInfo;}
      [data-disabled=true] & {color: $colorFontDisabled;}
      }

    &__error-container {
      &__error {}
      }

    &__hint-trigger {
      display         : flex;
      position        : absolute;
      justify-content : center;
      align-items     : center;
      width           : $input-height;
      top             : -0.6rem;
      right           : 0;
      z-index         : 1;

      &__trigger {
        display          : flex;
        position         : relative;
        justify-content  : center;
        align-items      : center;
        height           : 1.4rem;
        padding          : 0;
        cursor           : pointer;
        border-radius    : 50%;
        //background-color : $input-color-background;

        &::before {
          //position         : absolute;
          //content          : "";
          //width            : calc(100% + 0.8rem);
          //height           : 0.2rem;
          //top              : calc(100% / 2 - 0.1rem);
          //background-color : $input-color-background;
          }

        &__icon {
          top       : 0;
          font-size : 1.4rem;
          color     : $input-color-accent;
          z-index   : 1;
          }
        }
      }
    }

  &__hint {

    &__block {
      max-height       : 0;
      transition       : $transitionDefault 50ms, opacity $transitionDefault 0s;
      transform        : scale(0.6);
      transform-origin : right bottom;
      font-family      : inherit;
      opacity          : 0;
      visibility       : hidden;

      &__content {
        position         : relative;
        padding          : 1rem 1.5rem;
        font-family      : inherit;
        font-size        : 1.3rem;
        font-weight      : 600;
        line-height      : 1.5;
        color            : $input-color-font-hint_block;
        border           : 0.1rem solid $input-color-background-hint_block;
        border-radius    : $input-border-radius;
        background-color : $input-color-background-hint_block;

        &::after {
          position         : absolute;
          content          : " ";
          width            : 0;
          height           : 0;
          right            : calc(var(--input-height, 5rem) / 2 - 0.6rem);
          bottom           : -1.1rem;
          pointer-events   : none;
          border           : 0.5rem solid transparent;
          border-top-color : $input-color-background-hint_block;
          }
        }

      &--visible {
        max-height     : 50rem;
        padding-bottom : 1.5rem;
        transition     : $transitionDefault, opacity $transitionDefault 50ms;
        transform      : scale(1);
        opacity        : 1;
        visibility     : visible;
        }
      }

    &__floating {
      position         : absolute;
      margin           : 0 0 0 1rem;
      padding          : 1rem 1.5rem;
      right            : 0;
      bottom           : calc(100% + 1.1rem);
      transition       : $transitionDefault;
      transform        : scaleY(0.4);
      transform-origin : right bottom;
      font-family      : inherit;
      border           : 0.2rem solid $input-color-accent;
      border-radius    : calc(var(--input-border-radius, 0.3rem) + 1rem);
      background-color : $input-color-background;
      opacity          : 0;
      visibility       : hidden;
      box-shadow       : 0.4rem 0.4rem 1rem rgba(0, 0, 0, .1), 0 0 0.6rem 0.1rem rgba(0, 0, 0, .12);
      z-index          : 2;

      &::after, &::before {
        position       : absolute;
        content        : " ";
        width          : 0;
        height         : 0;
        right          : calc(var(--input-height, 5rem) / 2 - 1rem);
        pointer-events : none;
        border         : 0.8rem solid transparent;
        }

      &::after {
        bottom           : -1.5rem;
        border-top-color : $input-color-background;
        }

      &::before {
        bottom           : -1.7rem;
        border-top-color : $input-color-accent;
        }

      &__content {
        font-family : inherit;
        font-size   : 1.3rem;
        font-weight : 600;
        line-height : 1.5;
        color       : $input-color-font;
        }

      &--visible {
        transform  : scaleY(1);
        opacity    : 1;
        visibility : visible;
        }
      }
    // @todo - different on services
    &__modal {
      font-family      : inherit;
      background-color : rgba(0, 0, 0, 0.5);

      .custom-modal__container {
        font-family : inherit;

        .custom-modal__container__header {
          .custom-modal__container__title {
            margin : 0;
            }
          }

        .custom-modal__container__body {
          font-family : inherit;
          font-size   : 1.5rem;
          font-weight : 600;
          line-height : 1.7;
          color       : $input-color-font;
          }
        }
      }
    }

  &__error {
    padding     : 5px calc(var(--input-height, 5rem) / 2) 5px;
    font-family : inherit;
    font-size   : 1.3rem;
    font-weight : 600;
    line-height : 1.3;
    color       : #f20530;
    }

  &__messages {
    padding-top  : 7px;
    padding-left : 25px;

    [data-icon-left=true] & {padding-left : 18.5px;}
    //[data-disabled=true] & {filter: grayscale(1);}
    }
  }
// ===== DYNAMIC ERRORS ======
.input-custom {
  $valid-input-color      : $colorFontSuccess;
  $invalid-input-color    : $colorFontError;
  $valid-input-bg-color   : $colorBackgroundSuccess;
  $invalid-input-bg-color : $colorBackgroundError;

  &--valid {
    .input-custom__field-container {
      &__label {color : $valid-input-color;}
      &__field {
        outline-color : $valid-input-color;
        &:hover, &:focus {
          outline        : 2px solid $valid-input-color;
          outline-offset : -2px;
          }
        &:focus + label {color : $valid-input-color;}
        }
      }
    }

  &--invalid {
    .input-custom__field-container {
      &__label {color : $invalid-input-color;}
      &__field {
        background-color : $invalid-input-bg-color;
        &:hover, &:focus {
          outline        : 2px solid $invalid-input-color;
          outline-offset : -2px;
          }
        &:focus + label {
          color            : $invalid-input-color;
          background-color : $invalid-input-bg-color;
          }
        }
      }
    }
  }
// ======= DISABLED STATE =======
.input-custom__field-container--disabled {

  --input-color-font-label       : var(--colorFontDisabled);
  --input-color-font-label-upper : var(--colorFontDisabled);
  --input-color-font             : var(--colorFontDisabled);

  .input-custom__field-container {
    &__field {
      cursor : not-allowed;
      [data-theme=dark] & {background-color : #262626;}
      [data-theme=light] & {background-color : #e2e2e2;}
      }
    }
  }