@import "src/styles/variables";

.app {
  display    : flex;
  flex-flow  : column;
  min-height : 101vh;

  &__container {
    display    : flex;
    flex-flow  : column;
    flex-grow  : 1;
    transition : 0s ease, filter calc(var(--transitionDurationDefault) / 2) $transitionTimingFunctionDefault;
    }

  &--noscroll {
    min-height : 100vh;
    overflow: hidden;
    }
  }


