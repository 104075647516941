@import "src/styles/variables";

.trigger {
  margin-right : 10px;

  & :global .ripple-button {
    &:focus-visible {
      transition     : 0s ease;
      outline        : 1px solid $colorFontInfo;
      outline-offset : -1px;
      }
    }
  }