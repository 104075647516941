.modal-layout {
  display         : flex;
  position        : fixed;
  justify-content : center;
  align-items     : center;
  width           : 100%;
  height          : 100%;
  padding         : 10px;
  top             : 0;
  left            : 0;
  transition      : calc(var(--transitionDurationDefault) / 2) $transitionTimingFunctionDefault;
  opacity         : 0;
  visibility      : hidden;
  z-index         : 999;

  &--reveal {
    opacity    : 1;
    visibility : visible;

    .modal-layout__cover {
      opacity    : 1;
      visibility : visible;
      }
    }

  &__cover {
    position   : fixed;
    width      : 100%;
    height     : 100%;
    top        : 0;
    left       : 0;
    transition : calc(var(--transitionDurationDefault) / 2) $transitionTimingFunctionDefault, z-index 0s;
    opacity    : 0;
    visibility : hidden;
    [data-theme=light] & {background-color : var(--modal-layout-background-color-light, rgba(0, 0, 0, .1))}
    [data-theme=dark] & {background-color : var(--modal-layout-background-color-dark, rgba(0, 0, 0, .4))}
    }

  &__layer {
    position   : absolute;
    //overflow   : hidden;
    //transition : $transitionTimingFunctionDefault calc(var(--transitionDurationDefault) / 2), z-index 0s, transform 0s, filter 0s;
    transition : calc(var(--transitionDurationDefault) / 2) $transitionTimingFunctionDefault, z-index 0s;

    &--covered {
      transform : scale(0.99);
      filter    : blur(var(--layer-background-blur, 10px));
      }

    &__content {
      //overflow   : hidden;
      }
    }
  }