.draft-editor-block {

  --brInner             : var(--editorBorderRadiusInner, 0);
  --brOuter             : var(--editorBorderRadiusOuter, var(--borderRadiusContainerInnerEdge));
  --cOutline            : var(--editorColorOutline, var(--colorBackgroundBasic));
  --cBackground         : var(--editorColorBackground, var(--colorBackgroundContent));
  --cBackgroundControls : var(--editorColorBackgroundControls, var(--colorBackgroundContent));
  --mControls           : var(--editorMarginControls, 0);

  &--cardItem {

    --brInner             : var(--editorBorderRadiusInner, var(--borderRadiusContainerInner));
    --brOuter             : var(--editorBorderRadiusOuter, var(--borderRadiusContainerInnerEdge));
    --cOutline            : var(--editorColorOutline, transparent);
    --cBackground         : var(--editorColorBackground, var(--colorBackgroundContent));
    --cBackgroundControls : var(--editorColorBackgroundControls, var(--colorBackgroundContent));
    --mControls           : var(--editorMarginControls, var(--marginContainerInner));
    }

  .draft-control-panel {
    justify-content  : flex-start;
    margin-bottom    : var(--mControls);
    padding          : 10px 10px 5px;
    border           : 1px solid var(--cOutline);
    border-bottom    : none;
    border-radius    : var(--brOuter) var(--brOuter) var(--brInner) var(--brInner);
    background-color : var(--cBackgroundControls);

    &__container {
      &__block {
        padding-right : 10px;

        &:last-child {padding-right : 0;}

        &__button-container {
          &__button {}
          }
        }
      }
    }

  .content-editor__area {
    padding          : 0;
    border-radius    : var(--brInner) var(--brInner) var(--brOuter) var(--brOuter);
    outline          : 1px solid var(--cOutline) !important;
    outline-offset   : -1px;
    background-color : var(--cBackground);

    .public-DraftEditor-content {padding : 15px 20px;}
    .content-editor__area__preview {padding : 15px 20px;}

    &--split {
      .public-DraftEditor-content {padding : 15px 5px 15px 20px;}
      .content-editor__area__preview {padding : 15px 20px 15px 5px;}
      }
    }
  }