.draft-button-bottom {
  display         : flex;
  justify-content : flex-end;
  margin          : 0;
  padding         : 15px 20px;
  }

.draft-button-select-buttons {
  display   : flex;
  flex-flow : row wrap;
  //padding-top : 10px;

  & .ripple-button-container {
    height        : 3rem;
    margin-right  : 5px;
    margin-bottom : 5px;

    .ripple-button {

      &__title {
        font-family : $fontFamilySecondary;
        font-size   : $fontSizeSecondarySmall;
        font-weight : $fontWeightSecondaryBold;
        line-height : $lineHeightSecondary;
        }

      &__icon {
        font-size : 1.1rem;
        }
      }
    }
  }

.draft-button-layout {
  display : flex;

  &__form {
    flex-grow   : 1;
    flex-shrink : 1;
    width       : 50%;
    z-index     : 1;
    }

  &__preview {
    display          : flex;
    flex-shrink      : 1000;
    justify-content  : center;
    width            : 100%;
    min-width        : 0;
    max-width        : 450px;
    margin-right     : -30px;
    padding-right    : 30px;
    border-radius    : $borderRadiusContainerInnerEdge 0 0 $borderRadiusContainerInnerEdge;
    background-color : $colorBackgroundBasic;
    }
  }

.draft-button-subtitle {
  padding-top    : 15px;
  padding-bottom : 5px;
  font-family    : $fontFamilySecondary;
  font-size      : $fontSizeSecondaryTiny;
  font-weight    : $fontWeightSecondaryRegular;
  line-height    : $lineHeightSecondarySmall;
  color          : $colorFontLight;
  }

.draft-button-colors {
  display     : flex;
  flex-flow   : row wrap;
  padding-top : 5px;

  &__color {
    display         : flex;
    justify-content : center;
    align-items     : center;
    width           : 30px;
    height          : 30px;
    margin-right    : 5px;
    margin-bottom   : 5px;
    cursor          : pointer;
    transition      : $transitionDefault;
    border          : none;
    border-radius   : 50%;
    outline         : 2px solid transparent;
    outline-offset  : -2px;

    &:hover {
      outline-color : $colorFontContrast;
      }

    &:focus-visible {
      outline-color : $colorFontTitle;
      }

    &:active {
      outline-color : transparent;
      opacity       : 0.6;
      }

    &__selected {
      font-size : 11px;
      color     : $colorBackgroundContent;
      }
    }
  }