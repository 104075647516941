.table-mini {
  padding : 0.7rem 0;

  &__container {
    position       : relative;
    padding        : 15px 15px;
    border-radius  : 10px;
    outline        : 1px dashed;
    outline-offset : -1px;
    [data-theme=dark] & {outline-color : $colorBackgroundTop}
    [data-theme=light] & {outline-color : $colorBackgroundBasic}

    &__titles {
      display               : grid;
      position              : absolute;
      width                 : calc(100% - 30px);
      top                   : -0.7rem;
      grid-template-rows    : auto;
      grid-template-columns : var(--mini-table-grid, auto);
      }

    &__rows {
      &__loading {
        .animated-loading__content {
          justify-content : center;
          padding         : 30px 0;
          &__ring {margin : 0}
          &__message {
            padding-left : 7px;
            font-size    : 1.5rem;
            color        : $colorAccentBasic;
            }
          }
        }
      }

    &__create {
      position         : absolute;
      padding          : 0 5px;
      right            : 10px;
      bottom           : -1.2rem;
      background-color : $colorBackgroundContent;
      }
    }
  }

.table-mini-title {
  display         : flex;
  justify-content : var(--title-align, flex-start);
  align-items     : center;
  min-width       : 0;

  &__content {
    width            : min-content;
    min-width        : 0;
    padding          : 0 10px;
    overflow         : hidden;
    font-size        : 1.1rem;
    font-weight      : 700;
    line-height      : 1.3;
    text-align       : var(--col-align, left);
    white-space      : nowrap;
    text-overflow    : ellipsis;
    color            : $colorFontLight;
    background-color : $colorBackgroundContent;
    }
  }

.table-mini-row {
  display               : grid;
  min-width             : 0;
  border-bottom         : 1px dashed;
  grid-template-rows    : auto;
  grid-template-columns : var(--mini-table-grid, auto);
  [data-theme=dark] & {border-bottom-color : $colorBackgroundTop}
  [data-theme=light] & {border-bottom-color : $colorBackgroundBasic}

  &:last-child {border-bottom : none;}

  &--empty {
    padding        : 3px 0;
    font-size      : 1.3rem;
    font-weight    : 700;
    line-height    : 1.5;
    text-align     : center;
    text-transform : uppercase;
    color          : $colorFontLight;
    }

  &__action {
    display         : flex;
    justify-content : center;
    align-items     : center;
    }

  &__cell {
    display     : flex;
    align-items : center;
    width       : 100%;
    min-width   : 0;
    padding     : 3px 10px;
    border-left : 1px dashed;
    [data-theme=dark] & {border-left-color : $colorBackgroundTop}
    [data-theme=light] & {border-left-color : $colorBackgroundBasic}

    &:first-child {border-left : none}

    &__content {
      width         : 100%;
      min-width     : 0;
      overflow      : hidden;
      font-size     : 1.5rem;
      font-weight   : 400;
      line-height   : 1.3;
      text-align    : var(--col-align, left);
      white-space   : nowrap;
      text-overflow : ellipsis;
      color         : $colorFontCommon;

      &__bool {
        font-size : 1.3rem;
        color     : $colorFontCommon;
        }
      }

    &--editable {
      transition : $transitionDefault;

      &:hover {
        cursor            : pointer;
        border-left-color : transparent;
        border-radius     : 5px;
        [data-theme=dark] & {background-color : #404040}
        [data-theme=light] & {background-color : #d9d9d9}
        .table-mini-row__cell__content {font-weight : 600;}
        }
      }
    }

  &--editable {
    transition : $transitionDefault;
    &:hover {
      cursor           : pointer;
      border-radius    : 5px;
      background-color : $colorBackgroundBasicLight;
      .table-mini-row__cell__content {color : $colorFontTitle;}
      }
    }
  }

.table-mini-button {
  .ripple-button {
    width : var(--buttons-dimension, 2.6rem);

    &__icon {
      width     : var(--buttons-dimension, 2.6rem);
      height    : var(--buttons-dimension, 2.6rem);
      font-size : calc(var(--buttons-dimension, 2.6rem) / 2 + 0.2rem);
      }
    }
  }