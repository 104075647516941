.controls-color-panel-button-container {
  position : relative;
  }

.controls-color-panel {
  position         : absolute;
  padding          : $paddingContainerOuter;
  top              : calc(100% + 0.5rem);
  left             : -1rem;
  transition       : $transitionDefault;
  transform        : scale(0.6);
  transform-origin : top left;
  border-radius    : $borderRadiusContainerOuter;
  background-color : $colorBackgroundBasic;
  opacity          : 0;
  visibility       : hidden;
  z-index          : 999;

  [data-theme=light] & {
    box-shadow : 1px 1px 10px rgba(0, 0, 0, .2), 0 0 2px rgba(0, 0, 0, .1);
    }

  [data-theme=dark] & {
    box-shadow : 1px 1px 10px rgba(255, 255, 255, .2), 0 0 2px rgba(255, 255, 255, .4);
    }

  &__themes {
    display : flex;

    &__theme {
      display       : flex;
      flex-flow     : row wrap;
      width         : calc(2.5rem * 5 + 2rem + 0.2rem * 10);
      padding       : 1rem;
      border-radius : $borderRadiusContainerInnerEdge;

      &:first-child {
        margin-right     : 0.5rem;
        background-color : #fff;
        }

      &:first-child &__color {
        &__item:hover {
          box-shadow : 0 0 0 0.1rem #000, 0 0 0 0.2rem #fff;
          }
        &__check {
          color            : #fff;
          background-color : rgba(0, 0, 0, .3);
          }
        }

      &:last-child {
        background-color : #202020;
        }

      &:last-child &__color {
        &__item:hover {
          box-shadow : 0 0 0 0.1rem #fff, 0 0 0 0.2rem #000;
          }
        &__check {
          color            : #000;
          background-color : rgba(255, 255, 255, .3);
          }
        }

      &__color {
        position : relative;
        padding  : 2px;

        &__item {
          content       : "";
          width         : 2.5rem;
          height        : 2.5rem;
          transition    : $transitionDefault;
          border-radius : 50%;

          &:hover {cursor : pointer;}
          }

        &__check {
          display         : flex;
          position        : absolute;
          justify-content : center;
          align-items     : center;
          width           : 100%;
          height          : 100%;
          top             : 0;
          left            : 0;
          color           : #fff;
          }
        }
      }
    }

  &__bottom {
    display         : flex;
    justify-content : center;

    &__button {
      margin-top : $paddingContainerOuter;

      .ripple-button {
        &__title {
          font-family : $fontFamilySecondary;
          font-size   : $fontSizeSecondarySmall;
          font-weight : $fontWeightSecondaryBold;
          }

        &__icon {
          font-size : 1.4rem;
          }
        }
      }
    }

  &--show {
    transform  : scale(1);
    opacity    : 1;
    visibility : visible;
    }
  }