.payment-methods {

  &__section {
    &__title {
      margin         : 0 0 1rem;
      font-size      : 1.5rem;
      font-weight    : 600;
      text-align     : center;
      text-transform : uppercase;
      color          : $colorFontCommon;

      & > span {
        font-family : inherit;
        font-size   : inherit;
        font-weight : 700;
        }
      }
    }

  &__selected-type {
    margin  : 0 0 4rem;
    padding : 1rem 0;

    &__single,
    &__subscription {
      margin         : 0;
      font-family    : $fontFamilyPrimary;
      font-size      : 1.8rem;
      font-weight    : 700;
      text-align     : center;
      text-transform : uppercase;
      color          : $colorFontCommon;

      & > span {
        font-family    : inherit;
        text-transform : uppercase;
        color          : $colorFontWarning;

        &:first-child {
          margin-bottom : -0.5rem;
          font-size     : 2.5rem;
          font-weight   : 700;
          }

        &:nth-child(2),
        &:last-child {
          font-weight : 800;
          }
        }
      }
    }

  &__methods {
    margin-bottom : 5rem;
    overflow      : hidden;

    &__container {
      display         : flex;
      flex-flow       : row wrap;
      justify-content : center;
      margin-top      : -10px;
      margin-right    : -10px;
      }

    &__method {
      width   : calc(100% / 4);
      padding : 10px 10px 0 0;

      &__container {
        position       : relative;
        padding-bottom : 60%;
        cursor         : pointer;
        user-select    : none;
        transition     : $transitionDefault;
        border         : 0.1rem solid;
        border-color   : var(--icon-color, $colorFontWarning);
        border-radius  : 1rem;

        &::before {
          position         : absolute;
          content          : '';
          width            : 100%;
          height           : 100%;
          top              : 0;
          left             : 0;
          transition       : $transitionDefault;
          border-radius    : 1rem;
          background-color : var(--icon-color, $colorFontWarning);
          opacity          : 0;
          visibility       : hidden;
          z-index          : 0;
          }

        &:hover {
          &::before {
            opacity    : 0.1;
            visibility : visible;
            }
          }

        &:active {
          border-color : transparent;

          &::before {
            opacity    : 0.2;
            visibility : visible;
            }

          .payment-methods__methods__method__container {
            &__dev {
              background-color : var(--icon-color, $colorFontWarning);
              }
            }
          }

        &__content {
          display         : flex;
          position        : absolute;
          flex-flow       : column;
          justify-content : center;
          align-items     : center;
          width           : 100%;
          height          : 100%;
          top             : 0;
          left            : 0;
          z-index         : 1;

          &__icon {
            padding-top : 1rem;
            font-size   : 3.5rem;
            color       : var(--icon-color, $colorFontWarning);
            }

          &__name {
            margin      : 0;
            padding-top : 0.5rem;
            font-family : $fontFamilyPrimary;
            font-size   : 1.5rem;
            font-weight : 700;
            color       : var(--icon-color, $colorFontCommon);
            }
          }

        &__currencies {
          display   : flex;
          position  : absolute;
          max-width : calc(50% - 0.8rem);
          top       : 0.6rem;
          right     : 0.8rem;
          z-index   : 1;

          &__currency {
            display         : flex;
            justify-content : center;
            align-items     : center;
            width           : 2rem;
            height          : 2rem;

            &__icon {
              color : var(--icon-color, $colorFontTitle);
              }
            }
          }

        &__dev {
          position         : absolute;
          max-width        : calc(50% - 0.8rem);
          height           : 2rem;
          padding          : 0.4rem 0.8rem;
          top              : 0.4rem;
          left             : 0.4rem;
          overflow         : hidden;
          transition       : $transitionDefault;
          font-size        : 1.1rem;
          font-weight      : 800;
          line-height      : 1;
          white-space      : nowrap;
          text-transform   : uppercase;
          text-overflow    : ellipsis;
          color            : $colorBackgroundContent;
          border-radius    : 0.7rem;
          background-color : $colorFontWarning;
          z-index          : 1;
          }

        &--selected {
          background-color : var(--icon-color, $colorFontWarning);

          &::before {
            opacity    : 1;
            visibility : visible;
            }

          &:hover {
            &::before {
              opacity    : 1;
              visibility : visible;
              }
            }

          &:active {
            &::before {
              opacity    : 1;
              visibility : visible;
              }
            }

          .payment-methods__methods__method__container {

            &__currencies__currency__icon,
            &__content__icon,
            &__content__name {
              color : #fff;
              }

            &__dev {
              background-color : var(--icon-color, transparent);
              }
            }
          }

        &--unsupported {
          cursor           : initial;
          border-color     : $colorBackgroundBasic;
          background-color : $colorBackgroundBasic;

          &::before {
            display : none;
            }

          &:hover {
            &::before {
              opacity    : 0;
              visibility : hidden;
              }
            }

          &:active {
            border-color : $colorBackgroundBasic;

            &::before {
              opacity    : 0;
              visibility : hidden;
              }

            .payment-methods__methods__method__container__dev {background-color : $colorFontWarning;}
            }

          .payment-methods__methods__method__container {

            &__currencies__currency__icon,
            &__content__icon,
            &__content__name {
              opacity : 0.8;
              }
            }
          }

        &--locked {
          border-color     : #e9e9e9;
          background-color : #e9e9e9;

          &::before {
            display : none;
            }

          &:hover {
            &::before {
              opacity    : 1;
              visibility : visible;
              }
            }

          &:active {
            &::before {
              opacity    : 1;
              visibility : visible;
              }

            .payment-methods__methods__method__container__dev {background-color : #ffa42e;}
            }

          .payment-methods__methods__method__container {

            &__currencies__currency__icon,
            &__content__icon,
            &__content__name {
              color : #a3a3a3;
              }

            &__dev {box-shadow : 0.1rem 0.1rem 0.4rem 0.1rem rgba(0, 0, 0, .15);}
            }
          }
        }
      }
    }
  }

@media only screen and (max-width : 830px) {
  .payment-methods {

    &__section__title {
      text-align : left;
      }

    &__methods {
      justify-content : flex-start;
      &__method {
        width : calc(100% / 3);
        }
      }
    }
  }

@media only screen and (max-width : 620px) {
  .payment-methods {
    &__methods {
      &__method {
        width : calc(100% / 2);
        }
      }
    }
  }

@media only screen and (max-width : 330px) {
  .payment-methods {
    &__methods {
      &__method {
        width : calc(100% / 1);
        }
      }
    }
  }