:root {font-size : 62.5%}
* {box-sizing : border-box}

::after,
::before {
  box-sizing : border-box
  }

%scrollbar-main {
  width            : 10px;
  height           : 10px;
  background-color : #fff;
  [data-theme=dark] & {background-color : #202020}
  &:active {
    background-color : #d0d0d0;
    [data-theme=dark] & {background-color : #101010}
    }
  }
%scrollbar-track-main {
  margin           : 5px 5px;
  border-radius    : 5px;
  background-color : #fff;
  [data-theme=dark] & {background-color : #202020}
  }
%scrollbar-thumb-main {
  border-radius    : 5px;
  background-color : #989898;
  [data-theme=dark] & {background-color : #383838}
  }

::-webkit-scrollbar {@extend %scrollbar-main}
::-webkit-scrollbar-track {@extend %scrollbar-track-main}
::-webkit-scrollbar-thumb {@extend %scrollbar-thumb-main}

body * {
  %scrollbar-content {
    width            : 0.6rem;
    height           : 0.6rem;
    background-color : transparent;
    [data-theme=dark] & {background-color : transparent}
    &:active {
      background-color : transparent;
      [data-theme=dark] & {background-color : transparent}
      }
    }
  %scrollbar-track-content {
    margin           : 1.5rem 1.5rem;
    border-radius    : 0.5rem;
    background-color : transparent;
    [data-theme=dark] & {background-color : transparent}
    &:hover {
      background-color : transparent;
      [data-theme='dark'] & {background : transparent}
      }
    &:active {
      background-color : transparent;
      [data-theme=dark] & {background-color : transparent}
      }
    }
  %scrollbar-thumb-content {
    border-radius    : 0.3rem;
    background-color : #dedede;
    [data-theme=dark] & {background-color : #454545}
    &:hover {
      background-color : #cdcdcd;
      [data-theme=dark] & {background-color : #505050}
      }
    &:active {
      background-color : #b0b0b0;
      [data-theme=dark] & {background : #656565}
      }
    }

  &::-webkit-scrollbar {@extend %scrollbar-content}
  &::-webkit-scrollbar-track {@extend %scrollbar-track-content}
  &::-webkit-scrollbar-thumb {@extend %scrollbar-thumb-content}
  }

body {
  overflow-y : scroll;
  }

html, body {
  margin           : 0;
  padding          : 0;
  font-family      : $fontFamilyPrimary;
  font-size        : 1.6rem;
  font-weight      : $fontWeightPrimaryRegular;
  line-height      : 1.6;
  color            : $colorFontCommon;
  background-color : $colorBackgroundBasicLight;
  }

main {
  display   : flex;
  flex-flow : column;
  flex-grow : 1;
  margin    : 0;
  }

h1, h2, h3, h4, h5, h6 {
  margin-top          : 0;
  margin-bottom       : 0;
  font-family         : $fontFamilyPrimary;
  font-weight         : $fontWeightPrimaryBold;
  line-height         : 1.2;
  color               : $colorFontTitle;
  margin-block-end    : 0;
  margin-inline-start : 0;
  margin-inline-end   : 0;

  @media only screen and (max-width : 490px) {
    font-family : $fontFamilySecondary;
    font-weight : $fontWeightSecondaryBold;
    }
  }

p {
  font-family : $fontFamilyPrimary;
  font-weight : $fontWeightPrimaryRegular;
  color       : $colorFontCommon;

  @media only screen and (max-width : 490px) {
    font-family : $fontFamilySecondary;
    font-weight : $fontWeightSecondaryRegular;
    }
  }

strong, b {
  font-family : inherit;
  font-weight : 700;
  color       : $colorFontTitle;
  }

a {
  cursor                      : pointer;
  font-family                 : inherit;
  font-weight                 : inherit;
  text-decoration             : none;
  color                       : $colorFontInfo;
  background-color            : transparent;
  -webkit-tap-highlight-color : transparent;

  &:hover, :focus {
    text-decoration : underline;
    color           : #23527c;
    }
  }

li {-webkit-tap-highlight-color : transparent;}

p {margin : 0}

button {
  &:hover {cursor : pointer}
  &:focus {text-decoration : none}
  }

input {
  font-family : inherit;
  font-weight : 500;
  color       : #555;
  }

img {
  display   : block;
  max-width : 100%;
  }