.content-editor {
  position : relative;

  &__area {
    display  : flex;
    position : relative;

    & > .DraftEditor-root {
      width     : 100%;
      max-width : 100%;

      .public-DraftEditor-content {min-height : 13rem;}
      }

    .content-editor__area__preview {
      position : relative;

      &::before {
        position         : absolute;
        content          : "HTML";
        padding          : 0.2rem 0.7rem;
        top              : 0.3rem;
        right            : 0.3rem;
        font-family      : $fontFamilyPrimary;
        font-size        : 1.1rem;
        font-weight      : 700;
        border-radius    : 1rem;
        background-color : $colorBackgroundBasic;
        opacity          : 0.8;
        }
      }

    &--split {
      .DraftEditor-root, .content-editor__area__preview {
        width     : 100%;
        max-width : 50%;
        }
      }
    }

  &__default-styles {
    &__align {
      &--left, &--left > div {text-align : left}
      &--right, &--right > div {text-align : right}
      &--center, &--center > div {text-align : center}
      &--justify, &--justify > div {text-align : justify}
      }
    }
  }

// variables

%content-code-block-scrollbar {
  width  : 1.4rem;
  height : 1.4rem;
  }

%content-code-block-scrollbar-track {
  margin        : 0;
  transition    : $transitionDefault;
  border-radius : 0.7rem;
  background    : #f5f5f5;
  [data-theme='dark'] & {background : #1b1b1b}

  &:hover {
    background : #e7e7e7;
    [data-theme='dark'] & {background : #181818}
    }

  &:active {
    background : #dedede;
    [data-theme='dark'] & {background : #171717}
    }
  }

%content-code-block-scrollbar-thumb {
  border-radius : 0.7rem;
  background    : #e0e0e0;
  [data-theme='dark'] & {background : #252525}

  &:hover {
    background : #c7c7c7;
    [data-theme='dark'] & {background : #282828}
    }

  &:active {
    background : #acacac;
    [data-theme='dark'] & {background : #313131}
    }
  }

// default styles

//$default-article-header-six-font-size : 1.9rem;
$default-article-font-weight-common : 500;
$default-article-font-size-common   : 1.6rem;
$default-article-line-height-common : 1.6;
$default-article-font-family-common : $fontFamilyPrimary;


.content-editor {
  font-family                 : $default-article-font-family-common;
  font-size                   : 1rem;
  font-weight                 : $default-article-font-weight-common;
  --default-artcile-font-size : 1.6rem;
  overflow-wrap               : break-word;
  //--var-blog-article-header-six-font-size : 1.9rem;

  span, em, u {
    font-family : inherit;
    font-size   : inherit;
    font-weight : inherit;
    line-height : inherit;
    }

  strong {
    font-family : inherit;
    font-size   : inherit;
    line-height : inherit;
    }

  figure {margin : 0}

  &__header-one,
  &__header-two,
  &__header-three,
  &__header-four,
  &__header-five,
  &__header-six {
    margin      : 0;
    padding     : 0;
    font-family : $default-article-font-family-common;
    font-weight : 500;
    line-height : $default-article-line-height-common;
    color       : $colorFontTitle;
    }

  &__header-one {font-size : calc(var(--default-artcile-font-size) + 4 * 0.2rem);}
  &__header-two {font-size : calc(var(--default-artcile-font-size) + 2 * 0.2rem);}
  &__header-three {font-size : calc(var(--default-artcile-font-size) + 1 * 0.2rem);}
  &__header-four {font-size : calc(var(--default-artcile-font-size) + 0 * 0.2rem);}
  &__header-five {font-size : calc(var(--default-artcile-font-size) - 1 * 0.2rem);}
  &__header-six {font-size : calc(var(--default-artcile-font-size) - 1.5 * 0.2rem);}

  &__unstyled,
  &__paragraph {
    margin      : 0;
    padding     : 0;
    font-family : $default-article-font-family-common;
    font-size   : $default-article-font-size-common;
    font-weight : $default-article-font-weight-common;
    line-height : $default-article-line-height-common;
    color       : $colorFontCommon;
    }

  &__unstyled {}
  &__paragraph {text-indent : 2em}

  .public-DraftStyleDefault-pre {margin : 0}

  .public-DraftStyleDefault-pre > pre, // container in editor
  .draft-html-pre, // container in generated HTML
  .draft-html-pre-empty,
  pre.shiki {
    margin           : 6px 0;
    font-size        : $default-article-font-size-common;
    //color            : #353535 !important;
    border-radius    : 10px;
    background-color : #fafafa !important;

    [data-theme=dark] & {
      //color            : #969696 !important;
      background-color : #1b1b1b !important;
      //background-color : $colorBackgroundBasic !important;
      }
    }

  .draft-html-pre-empty {padding : 15px 20px 22.41px;}

  .public-DraftStyleDefault-pre > pre,
  .draft-html-pre,
  pre.shiki {
    max-width   : 100%;
    padding     : 15px 20px 8px;
    overflow    : auto;
    line-height : 2rem;
    }

  .public-DraftStyleDefault-pre > pre .public-DraftStyleDefault-block, // content in editor
  .draft-html-pre > code,
  pre.shiki > code { // content in generated HTML
    display        : block;
    max-width      : 100%;
    padding-bottom : 10px;
    overflow       : auto;
    white-space    : pre;


    &::-webkit-scrollbar-corner {background: transparent;}
    &::-webkit-scrollbar {@extend %content-code-block-scrollbar}
    &::-webkit-scrollbar-track {@extend %content-code-block-scrollbar-track}
    &::-webkit-scrollbar-thumb {@extend %content-code-block-scrollbar-thumb}
    }

  .public-DraftStyleDefault-pre .public-DraftStyleDefault-block, // content in editor
  .public-DraftStyleDefault-pre > pre, // content in editor
  .draft-html-pre > code {
    margin      : 0;
    font-family : Monospace, $fontFamilyPrimary;
    font-size   : 1.4rem;
    font-weight : 500;
    line-height : 1.8;
    color       : $colorFontTitle;
    }

  &__code-block {}

  &__blockquote {
    position      : relative;
    max-width     : 75rem;
    margin        : 0.6em auto;
    padding       : 3.5rem 4rem 4rem;
    font-family   : $default-article-font-family-common;
    //font-size     : $default-article-font-size-common;
    font-size     : 5rem;
    font-weight   : $default-article-font-weight-common;
    line-height   : 1.6;
    color         : $colorFontCommon;
    border        : 0.2rem solid $colorFontWarning;
    border-radius : 3rem;
    [data-theme=dark] & {
      border-color : $colorFontWarning;
      }

    &::before,
    &::after {
      display          : flex;
      position         : absolute;
      content          : '”';
      justify-content  : center;
      align-items      : center;
      width            : 3rem;
      height           : 1.5rem;
      padding-top      : 1.5rem;
      font-family      : serif;
      font-size        : 5rem;
      line-height      : 1;
      color            : $colorFontWarning;
      background-color : $colorBackgroundContent;
      }

    &::before {
      top       : -1rem;
      left      : 3.5rem;
      transform : rotate(180deg);
      }
    &::after {
      right  : 3.5rem;
      bottom : -1rem;
      }
    }

  .public-DraftStyleDefault-ul,
  .public-DraftStyleDefault-ol,
  ul, ol {
    margin  : 0;
    padding : 0;
    color   : $colorFontCommon;
    }

  ol { // only for html version
    width       : calc(100% + 0.6rem);
    margin-left : -0.6rem;
    }

  .public-DraftStyleDefault-orderedListItem { // remove padding for draft version
    padding-left : 0;
    }

  &__unordered-list-item,
  &__ordered-list-item {
    margin-left : 1.5em;
    font-family : $default-article-font-family-common;
    font-size   : $default-article-font-size-common;
    font-weight : $default-article-font-weight-common;
    line-height : $default-article-line-height-common;

    &::before, &::marker { // marker is for html, before is for draft
      display    : block;
      position   : absolute;
      width      : 30px;
      left       : -36px;
      font-size  : $default-article-font-size-common;
      text-align : right;
      }
    }

  &__unordered-list-item {
    &::before, &::marker { // marker is for html, before is for draft
      line-height : 1;
      color       : $colorFontLight;
      }
    }

  &__ordered-list-item {
    padding-left : 0.6rem; // only for html version

    &::before, &::marker { // marker is for html, before is for draft
      font-weight : 700;
      font-style  : italic;
      color       : $colorFontLight;
      }
    }
  }

.shiki,
.shiki span {
  [data-theme=dark] & {
    font-weight     : var(--shiki-dark-font-weight) !important;
    //background-color: var(--shiki-dark-bg) !important;
    /* Optional, if you also want font styles */
    font-style      : var(--shiki-dark-font-style) !important;
    text-decoration : var(--shiki-dark-text-decoration) !important;
    color           : var(--shiki-dark) !important;
    }
  }