.foldingBlock {
  &__head {
    display        : flex;
    align-items    : center;
    padding-bottom : 10px;
    cursor         : pointer;
    transition     : $transitionDefault;
    color          : $colorFontCommon;

    [data-folded=true] & {padding-bottom : 0;}

    &:hover {
      color : $colorFontTitle;
      }

    &__title {
      padding-right : 10px;
      transition    : $transitionDefault;
      font-family   : $fontFamilySecondary;
      font-size     : $fontSizeSecondarySmall;
      font-weight   : $fontWeightSecondaryRegular;
      color         : inherit;
      }

    &__icon {
      transition : $transitionDefault;
      font-size  : $fontSizeSecondaryTiny;
      color      : inherit;

      [data-folded=true] & {transform : rotate(-180deg);}
      }
    }
  }