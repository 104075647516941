.df {
  &__input-warn {
    .input-custom {
      &__field-container {
        &__field {
          border-color : $colorFontWarning;
          &:focus, &:hover {
            outline-color : $colorFontWarning;
            & + label {
              color : $colorFontWarning;
              }
            }
          }
        &__label {color : $colorFontWarning;}
        }
      }
    }

  &__field {
    &__internal-help, {
      font-size   : 1.5rem;
      font-weight : 600;
      line-height : 1.5;
      color       : $colorFontInfo;
      &:visited, &:hover, &:focus {color : $colorFontInfo}
      }

    .content-editor__unstyled {
      font-size   : 1.5rem;
      font-weight : 600;
      line-height : 1.5;
      }
    }

  &__guide {
    padding-top    : 4px;
    padding-bottom : 4px;

    &__content {
      display : flex;

      &__icon {
        display         : flex;
        flex-shrink     : 0;
        justify-content : center;
        align-items     : center;
        width           : 5rem;
        height          : 1.6rem;
        font-size       : 1.6rem;
        color           : $colorFontInfo;
        }

      &__text {
        padding-top  : 0.1rem;

        &__unstyled {
          padding-bottom : 2px;
          font-family    : $fontFamilySecondary;
          font-size      : 1.3rem;
          font-weight    : $fontWeightSecondaryBold;
          line-height    : 1.3;

          .draft-link {
            font-family : inherit;
            font-weight : $fontWeightSecondaryBold;
            line-height : inherit;
            color       : $colorFontInfo;

            &:visited {color : $colorFontInfo}
            }
          }
        }
      }
    }

  &__note {
    padding-top    : 4px;
    padding-bottom : 4px;

    &__content {

      &__head {
        display        : flex;
        align-items    : center;
        padding-bottom : 4px;

        &__icon {
          display         : flex;
          justify-content : center;
          align-items     : center;
          width           : 5rem;
          height          : 1.6rem;
          font-size       : 1.6rem;
          color           : $colorFontInfo;
          }

        &__title {
          margin       : 0;
          font-family  : $fontFamilyPrimary;
          font-size    : 1.3rem;
          font-weight  : $fontWeightPrimaryBold;
          color        : $colorFontTitle;
          }
        }

      &__body {
        padding-left : 50px;

        &__text {

          &__unstyled {
            font-family : $fontFamilySecondary;
            font-size   : 1.3rem;
            font-weight : $fontWeightSecondaryBold;
            line-height : 1.3;

            .draft-link {
              font-family : inherit;
              font-weight : $fontWeightSecondaryBold;
              line-height : inherit;
              color       : $colorFontInfo;

              &:visited {color : $colorFontInfo}
              }
            }
          }
        }
      }
    }
  }