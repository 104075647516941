@import "/src/styles/variables";

.container {
  display             : flex;
  width               : 100%;
  min-width           : 0;
  max-width           : 100%;
  height              : 100%;
  //height              : 100%;
  padding             : 0 20px;
  --header-user-width : 210px;
  --header-logo-width : 210px;

  @media (max-width : 1090px) {--header-logo-width : 57px;}

  &__logo {
    display     : flex;
    flex-shrink : 0;
    align-items : center;
    min-width   : var(--side-width);
    height      : 100%;
    user-select : none;
    }

  &__center {
    display         : flex;
    flex-grow       : 1;
    flex-shrink     : 1;
    justify-content : center;
    align-items     : center;
    min-width       : 0;

    &__filler {
      flex-grow   : 10;
      flex-shrink : 10;
      min-width   : var(--filler-width, 350px);
      max-width   : 350px;
      }

    &__nav {
      flex-grow   : 20;
      flex-shrink : 5;
      min-width   : 0;
      height      : 100%;
      }

    &__contacts {
      display     : flex;
      flex-grow   : 2;
      flex-shrink : 10;
      min-width   : 0;
      max-width   : 350px;
      }
    }

  &__user {
    display         : flex;
    flex-shrink     : 0;
    justify-content : flex-end;
    min-width       : var(--side-width);

    @media (max-width : 960px) {
      flex-shrink : 1;
      min-width   : 0;
      }
    }
  }


.nav {
  display         : flex;
  flex-grow       : 10;
  justify-content : center;
  align-items     : stretch;
  min-width       : 0;
  height          : 100%;

  &__item {
    display         : flex;
    position        : relative;
    justify-content : center;
    align-items     : center;
    min-width       : 0;
    height          : 100%;
    padding         : var(--h-nav-pad, 5px 15px);
    cursor          : pointer;
    user-select     : none;

    &:hover {
      text-decoration : none;
      color           : $colorFontLight;
      opacity         : 0.7;
      }

    > h3 {
      flex-shrink    : 1;
      min-width      : 0;
      max-width      : 100%;
      margin         : 0;
      font-size      : 1.3rem;
      font-weight    : 700;
      white-space    : nowrap;
      text-transform : uppercase;
      text-overflow  : ellipsis;
      color          : var(--h-nav-color, $colorFontTitle);
      }

    &__beta {
      position       : absolute;
      top            : 10px;
      right          : 0;
      font-family    : $fontFamilySecondary;
      font-size      : $fontSizeSecondaryTiny;
      font-weight    : $fontWeightSecondaryBold;
      text-transform : uppercase;
      color          : $colorFontWarning;
      }
    }

  &__link {
    flex-shrink : 1;
    min-width   : 0;
    &:focus-visible {outline : 1px solid $colorFontInfo;}

    > i {
      display   : flex;
      margin    : 0 6px 0 0;
      font-size : 1.1rem;
      color     : var(--h-nav-color, $colorFontTitle);

      > svg {
        width : 1.1rem;
        }
      }
    }
  }

.user {
  display     : flex;
  align-items : center;
  min-width   : 0;
  }