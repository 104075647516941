@import "../../../../../styles/variables";

.link, .link-contained {
  &:hover {text-decoration : none;}
  &__button {
    :global .ripple-button {
      padding : 0;
      &__icon {
        width     : 52px;
        padding   : 0;
        font-size : 1.6rem;
        color     : $colorFontCommon;

        [data-device=mobile] & {font-size : 2rem;}

        &__svg {
          width: 1.6rem;
          [data-device=mobile] & {width : 2rem;}
          }
        }
      &__title {
        padding-right  : 10px;
        padding-left   : 15px;
        font-family    : $fontFamilySecondary;
        font-size      : 1.5rem;
        font-weight    : $fontWeightSecondaryBold;
        text-align     : left;
        text-transform : initial;
        color          : $colorFontTitle;

        [data-device=mobile] & {font-size      : 1.7rem;}
        }
      }
    }
  }

.link__button :global .ripple-button {padding-left : 28px;}
.link-contained__button :global .ripple-button {padding-left : 20px;}

.user {
  max-height       : calc(100vh - 80px);
  padding          : $paddingContainerOuter 0 15px;
  overflow         : auto;
  border-radius    : $borderRadiusContainerOuter;
  background-color : $colorBackgroundBasicLight;

  --min-radius     : var(--borderRadiusContainerInner);
  --max-radius     : var(--borderRadiusContainerInnerEdge);

  [data-theme=dark] & {background-color : $colorBackgroundTop;}

  &__loading {
    display          : flex;
    justify-content  : center;
    align-items      : center;
    height           : 24.7rem;
    margin           : 0 $paddingContainerOuter;
    border-radius    : var(--max-radius);
    background-color : $colorBackgroundContent;
    }

  &__sign, &__info, &__limits, &__links {
    margin           : 0 $paddingContainerOuter $marginContainerInner;
    background-color : $colorBackgroundContent;
    }

  &__sign {
    border-radius    : var(--max-radius);
    background-color : transparent;
    .link-contained {
      .link-contained__button {
        height        : 6rem;
        margin-bottom : 2px;
        [data-device=mobile] & {
          height        : 8rem;
          margin-bottom : 2px;
          }
        }
      &:last-child .link-contained__button {[data-device=mobile] & {margin-bottom : 12px}}
      &:only-child .link-contained__button {margin-bottom : 2px}
      & :global .ripple-button {background-color : $colorBackgroundContent;}
      &:first-child :global .ripple-button {border-radius : var(--max-radius) var(--max-radius) var(--min-radius) var(--min-radius);}
      &:last-child :global .ripple-button {border-radius : var(--min-radius) var(--min-radius) var(--max-radius) var(--max-radius);}
      &:only-child :global .ripple-button {border-radius : var(--max-radius);}
      }
    }

  &__info {
    display        : flex;
    align-items    : center;
    min-width      : 0;
    padding-top    : 20px;
    padding-bottom : 10px;
    padding-left   : 20px;
    border-radius  : var(--max-radius) var(--max-radius) var(--min-radius) var(--min-radius);

    &__photo {
      flex-shrink : 0;
      width       : 52px;
      height      : 52px;

      &__image {
        border-radius : 50%;
        }
      }

    &__container {
      flex-grow    : 1;
      min-width    : 0;
      padding-left : 15px;

      &__name,
      &__email {
        margin        : 0;
        padding-right : 10px;
        overflow      : hidden;
        font-family   : $fontFamilySecondary;
        white-space   : nowrap;
        text-overflow : ellipsis;
        }

      &__name {
        margin      : 0;
        font-size   : 1.6rem;
        font-weight : $fontWeightSecondaryBold;
        line-height : 1.2;
        color       : $colorFontTitle;
        [data-device=mobile] & {font-size   : 2rem;}
        }

      &__email {
        font-family : $fontFamilySecondary;
        font-size   : 1.5rem;
        font-weight : $fontWeightSecondaryRegular;
        line-height : 1.2;
        color       : $colorFontTitle;
        [data-device=mobile] & {font-size   : 1.7rem;}
        }
      }
    }

  &__limits {
    cursor: pointer;
    border-radius : var(--min-radius);

    & :global .ripple-layout {
      display         : flex;
      justify-content : flex-start;
      align-items     : center;
      min-width       : 0;
      height          : 6rem;
      padding-left    : 20px;
      border-radius   : var(--min-radius);
      [data-device=mobile] & {padding-left    : 18px;}
      }

    &__icon, &__limit {
      display         : flex;
      flex-shrink     : 0;
      justify-content : center;
      align-items     : center;
      width           : 52px;
      }

    &__icon {
      padding   : 0;
      font-size : 2.6rem;
      }

    &__limit {
      font-family : $fontFamilySecondary;
      font-size   : 3.6rem;
      font-weight : $fontWeightSecondaryBold;
      line-height : 1.2;
      }

    &__text {
      flex-grow      : 1;
      padding-right  : 10px;
      padding-left   : 15px;
      overflow       : hidden;
      font-family    : $fontFamilySecondary;
      font-size      : 1.5rem;
      font-weight    : $fontWeightSecondaryBold;
      text-align     : left;
      white-space    : nowrap;
      text-transform : initial;
      text-overflow  : ellipsis;
      color          : $colorFontTitle;
      [data-device=mobile] & {font-size      : 1.7rem;}
      }
    }

  &__links {
    border-radius : var(--min-radius) var(--min-radius) var(--max-radius) var(--max-radius);
    .link-contained {
      &:first-child :global .ripple-button {border-radius : var(--min-radius);}
      &:last-child, &:only-child {
        :global .ripple-button {border-radius : var(--min-radius) var(--min-radius) var(--max-radius) var(--max-radius);}
        }
      }
    }
  }