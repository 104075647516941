@import "src/styles/variables";

.container {
  display             : flex;
  flex-flow           : row wrap;
  justify-content     : flex-end;
  align-items         : center;
  //width               : 100%;
  min-width           : 25rem;
  max-width           : 60rem;
  min-height          : 56px;
  padding             : 15px 8px 8px 15px;
  cursor              : pointer;
  user-select         : none;
  transform-origin    : 0 50%;
  animation           : notification-scale-animation 160ms forwards, notification-opacity-animation 120ms forwards;
  border-radius       : $borderRadiusContainerOuter;
  background-color    : $colorFontContrast;
  visibility          : visible;
  box-shadow          : 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.1), 0 0 1.6rem rgba(0, 0, 0, 0.2);
  overscroll-behavior : none;

  @keyframes notification-scale-animation {
    from {
      animation-timing-function : cubic-bezier(.03, .77, .88, .85);
      transform                 : scale(0.4);
      }
    to {transform : scale(1)}
    }

  @keyframes notification-opacity-animation {
    from {opacity : 0}
    to {opacity : 1}
    }

  &--hide {
    transform-origin : center center;
    animation        : notification-hide-animation 150ms forwards;

    @keyframes notification-hide-animation {
      from {
        opacity   : 1;
        transform : scale(1);
        }
      to {
        opacity   : 0;
        transform : scale(0.8);
        }
      }
    }

  &__message {
    display        : flex;
    flex-grow      : 1;
    align-items    : flex-start;
    max-width      : 100%;
    padding-right  : 12px;
    padding-bottom : 7px;

    &__icon {
      display         : flex;
      flex-shrink     : 0;
      justify-content : center;
      align-items     : center;
      width           : 22px;
      height          : 22px;
      margin-right    : 5px;
      color           : $colorBackgroundContent;
      }

    &__text {
      margin        : 0;
      padding       : 0;
      overflow      : hidden;
      font-family   : $fontFamilySecondary;
      font-size     : $fontSizeSecondary;
      font-weight   : $fontWeightSecondaryBold;
      line-height   : $lineHeightSecondary;
      text-overflow : ellipsis;
      color         : $colorBackgroundContent;
      overflow-wrap : break-word;
      }
    }

  &__buttons {
    display     : flex;
    flex-shrink : 0;
    //padding     : 0.8rem 0;
    }

  &--error {background-color : $colorFontError;}
  &--warning {background-color : $colorFontWarning;}
  &--success {background-color : $colorFontSuccess;}
  &--info {background-color : $colorFontInfo;}
  }

@media only screen and (max-width : 650px) {
  .container {
    position  : fixed;
    max-width : calc(100vw - 2rem);
    bottom    : 15rem;
    }
  }