.draft-control-panel {
  display          : flex;
  flex-flow        : row wrap;
  justify-content  : center;
  align-items      : center;
  padding          : 1rem 2.2rem;
  background-color : $colorBackgroundContent;
  border-radius    : 1.5rem;
  transition       : 0s ease;

  &__container {
    display      : flex;
    flex-flow    : row wrap;
    align-items  : center;
    margin-right : -2rem;

    &__block {
      display       : flex;
      flex-flow     : row wrap;
      align-items   : center;
      margin-right  : -0.2rem;
      padding-right : 2rem;
      }
    }
  }