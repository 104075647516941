//@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap&subset=latin-ext,vietnamese');
//@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import "variables";
@import "mixins";
@import "normalizer";
@import "../layouts/Index";
@import "layout";
@import "../components/Index";
@import "../helpers/forms/Index";
@import "../pagesComponents/Index";

.fa, .fab, .fad, .fal, .far, .fas {
  display: inline-flex !important;
  //display: flex;
  }

.card {@include card;}
.card--darker {@include card--darker;}

.card--success {@include card--success;}
.card--warning {@include card--warning;}
.card--error {@include card--error;}

.card-title {@include card-title;}

.card-messages {@include card-messages;}

.card-container {@include card-container;}

.card-content {@include card-content;}

.card-item {@include card-item;}

.card-item--info {@include card-item--info;}
.card-item--success {@include card-item--success;}
.card-item--warning {@include card-item--warning;}
.card-item--error {@include card-item--error;}

.card-item-not-contained {@include card-item-not-contained;}

.card-button {@include card-button;}

.card-ripple {@include card-ripple;}

.text-one-line {@include text-one-line;}

.interactive-container {@include interactive-container;}