
.draft-image-options {

  &__content {
    display    : flex;
    max-height : 100%;
    overflow   : auto;

    &__left-side,
    &__right-side {

      }

    &__left-side {
      //max-width : 60rem;
      flex-grow : 1;
      max-width : 90rem;
      padding   : 0 10px 20px 20px;
      }

    &__right-side {
      flex-grow     : 1;
      max-width     : 45rem;
      padding-right : 20px;
      //padding   : 0 1rem 0 3rem;
      }

    &__container {
      display          : flex;
      position         : relative;
      justify-content  : var(--align, center);
      align-items      : center;
      width            : 100%;
      //max-width        : 72.5rem;
      min-height       : 30rem;
      margin           : 0;
      overflow         : hidden;
      transition       : 100ms ease;
      border-radius    : $borderRadiusContainerInnerEdge;
      background-color : $colorBackgroundContent;

      &:hover &__align {
        opacity    : 1;
        visibility : visible;
        }

      &__image-container {
        max-height       : var(--max-image-height, 70rem);
        margin           : var(--container-margin, 0);
        transition       : 100ms ease;
        background-color : #a0aeb8;

        &__image {
          display    : flex;
          max-width  : 100%;
          max-height : inherit;
          margin     : var(--image-margin, 0);
          transition : 100ms ease;
          border     : var(--image-border, 0rem);
          }
        }

      &__align {
        display         : flex;
        position        : absolute;
        justify-content : center;
        align-items     : center;
        width           : 100%;
        height          : 100%;
        top             : 0;
        transition      : $transitionDefault;
        opacity         : 0;
        visibility      : hidden;

        &__container {
          display         : flex;
          flex-grow       : 1;
          justify-content : center;
          align-items     : center;
          height          : 100%;
          cursor          : pointer;

          &:hover {
            background-color : rgba(0, 0, 0, 0.5);
            }

          > i {
            transition : $transitionDefault;
            font-size  : 3rem;
            color      : #fff;
            filter     : drop-shadow(0.2rem 0.2rem 3rem rgba(0, 0, 0, 1));
            }
          }
        }
      }

    &__caption {
      padding     : 0.5rem 0;
      font-family : $fontFamilyPrimary;
      font-size   : 1.5rem;
      font-weight : 600;
      font-style  : italic;
      text-align  : center;
      }

    &__switch {
      padding : 0.7rem 0;
      }

    .input-custom {
      &:first-child {
        padding-top : 0;
        }
      }
    }

  &__bottom {
    display         : flex;
    justify-content : flex-end;
    align-items     : center;
    padding         : 4rem 0 0;
    }
  }