$card-advanced-part-transition : $transitionDefault calc(var(--transitionDurationDefault) / 2);

.cardAdvanced {
  @include card;
  transition : 0s ease, background-color $transitionDefault;

  &--darker {
    @include card--darker;
    }

  &--collapsed {
    align-self     : flex-start;
    padding-bottom : $paddingContainerOuter;
    }

  &--success {@include card--success;}
  &--warning {@include card--warning;}
  &--error {@include card--error;}

  &--hover {
    cursor : pointer;
    [data-theme=light] & {background-color : $colorAccentSoft}
    [data-theme=dark] & {background-color : $colorAccentSoft}
    }

  &__header {
    @include card-title($padding : $paddingContainerOuter calc(30px + var(--paddingContainerOuter)) $paddingContainerOuter calc(20px + var(--paddingContainerOuter)));
    display  : flex;
    position : relative;

    &__title {
      @include card-title($padding : 0);
      @include text-one-line;
      }

    &__icon {
      display         : flex;
      flex-shrink     : 0;
      justify-content : center;
      align-items     : center;
      width           : 2.5rem;
      height          : 1.6rem;
      font-size       : $fontSizeSecondaryTiny;

      &__arrow {
        transition : $transitionDefault;
        [data-collapsed=true] & {transform : rotate(-180deg);}
        }
      }

    &__loading {
      position : absolute;
      width    : 2.8rem;
      height   : 2.8rem;
      top      : 0;
      right    : $paddingContainerOuter;
      left     : auto;
      .animated-loading {
        &__content {
          &__ring {
            margin : 0;

            & > div {
              border-width : 3px;
              }
            }
          }
        }
      }
    }

  &__messages {
    @include card-messages;
    }

  &__container {
    @include card-container;
    max-height : var(--content-height, 4000px);
    transition : 0s ease, max-height $transitionDefault, opacity $card-advanced-part-transition, visibility $card-advanced-part-transition;
    opacity    : 1;
    visibility : visible;

    [data-collapsed=true] & {
      max-height : 0;
      overflow   : hidden;
      transition : 0s ease, opacity $transitionDefault, visibility $transitionDefault, max-height $card-advanced-part-transition;
      opacity    : 0;
      visibility : hidden;
      }

    &__content {
      @include card-content;

      [data-collapsed=true] & {
        }
      }
    }
  }
