@import "/src/styles/variables";

.header {
  display : flex;
  height  : 100%;
  left    : 0;

  &::after {
    position         : fixed;
    content          : "";
    width            : 100vw;
    height           : 100vh;
    top              : 0;
    left             : 0;
    transition       : $transitionDefault, width 0s ease, height 0s ease;
    background-color : rgba(0, 0, 0, 0.4);
    opacity          : 0;
    visibility       : hidden;
    z-index          : 998;
    }

  &__trigger {
    width            : 60px;
    height           : 59px;
    margin           : 0;
    padding          : 0;
    top              : 0;
    left             : 0;
    transition       : 0s ease;
    border           : none;
    border-radius    : 0 0 6px 0;
    background-color : var(--colorOpened, $colorBackgroundContent);

    &:focus-visible {
      outline        : 1px solid $colorFontInfo;
      outline-offset : -1px;
      }

    &:focus {
      outline : none;
      }

    &__icon {
      display         : flex;
      justify-content : center;
      align-items     : center;
      width           : 100%;
      height          : 100%;
      font-size       : 22px;
      color           : $colorFontTitle;

      > svg {
        width  : 22px;
        height : 22px;
        }
      }
    }

  &__logo {
    display         : flex;
    flex-grow       : 1;
    justify-content : flex-start;
    height          : 100%;
    }

  &__user {
    display         : flex;
    flex-shrink     : 0;
    justify-content : flex-end;
    align-items     : center;
    //width           : 60px;
    padding-right   : 20px;
    overflow        : hidden;
    }

  &__nav {
    display          : flex;
    position         : fixed;
    flex-flow        : column;
    width            : 100%;
    min-width        : 0;
    max-width        : 280px;
    height           : 100%;
    max-height       : 100vh;
    top              : 0;
    left             : 0;
    overflow         : auto;
    transition       : $transitionDefault, opacity 0s ease .2s, visibility 0s ease .2s;
    transform        : translate(-100%);
    border-radius    : 0 $borderRadiusContainerOuter $borderRadiusContainerOuter 0;
    background-color : $colorBackgroundBasicLight;
    z-index          : 999;

    [data-theme=dark] & {background-color : $colorBackgroundContent;}

    @media only screen and (max-width : 318px) {max-width : 100vw}
    }
  }

.header--show-nav {
  &::after {
    opacity    : 1;
    visibility : visible;
    }
  .header__nav {
    overflow            : auto;
    transition          : $transitionDefault, opacity 0s ease 0s, visibility 0s ease 0s;
    transform           : translate(0%);
    overscroll-behavior : none;
    }
  }

.nav {
  &__back {
    flex-shrink : 0;
    :global .ripple-button {
      justify-content : flex-start;
      &__icon {
        //padding-right : 10px;
        //padding-left  : 18px;
        margin-right : 5px;
        font-size     : 1.8rem;
        color         : $colorFontTitle;

        > svg {
          width   : 1.8rem;
          height  : 1.8rem;
          }
        }
      }
    }

  &__container {
    max-height : calc(100% - 6.5rem - var(--paddingContainerOuter));
    overflow   : auto;

    &__links {
      margin           : 0 $paddingContainerOuter 0 0;
      padding          : $paddingContainerOuter 0;
      overflow         : hidden;
      border-radius    : 0 $borderRadiusContainerInnerEdge $borderRadiusContainerInnerEdge 0;
      background-color : $colorBackgroundContent;
      [data-theme=dark] & {background-color : $colorBackgroundTop;}

      &__link {

        &:hover {text-decoration : none;}

        &__button {
          :global .ripple-button {
            &__icon {
              //padding-right : 18px;
              //padding-left  : 18px;
              margin-right : 5px;
              font-size    : 1.5rem;
              color        : var(--m-nav-color, $colorFontLight);

              > svg {
                width  : 1.5rem;
                height : 1.5rem;
                }
              }
            &__title {
              font-family    : $fontFamilySecondary;
              font-size      : $fontSizeSecondaryBig;
              font-weight    : $fontWeightSecondaryBold;
              text-align     : left;
              text-transform : initial;
              color          : var(--m-nav-color, $colorFontTitle);
              }
            }
          }
        }
      }
    }
  }