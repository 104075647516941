.select-country {

  &__container {

    &__select {
      overflow : auto;
      border   : none;

      &__container {

        &__trigger {
          position        : absolute;
          justify-content : flex-start;
          width           : 100%;
          height          : 100%;
          margin          : 0;
          top             : 0;
          left            : 0;
          transition      : $transitionDefault, padding 0s ease, margin 0s ease, border 0s ease, outline 0s ease, outline-offset 0s ease;
          //border          : 0.1rem solid #585858;
          border-radius   : 2.5rem;
          //[data-theme-dark] & {border-color : #bcbcbc}

          &:hover {
            //border-color : #7c7c7c;
            outline        : 0.1rem solid var(--color-accent-input, $colorAccentBasic);
            outline-offset : -0.1rem;
            }

          &:focus {
            //border-radius  : 1rem;
            outline        : 0.2rem solid var(--color-accent-input, $colorAccentBasic);
            outline-offset : -0.2rem;
            }

          .ripple-button {
            padding       : 0 0 0 5rem;
            border-radius : 2.5rem;
            &__title {
              flex-grow      : 1;
              transition     : 0s ease;
              font-size      : 1.5rem;
              font-weight    : 600;
              text-align     : left;
              text-transform : initial;
              }

            &__icon {
              flex-shrink : 0;
              width       : 5rem;
              padding     : 0;
              transition  : 0s ease;
              }
            }
          }
        }
      }
    }

  &--invalid {
    .select-country {
      &__container {
        & > .input-custom__field-container__label {color : #f44336}
        &__select__container__trigger {border-color : #f44336}
        }
      }
    }

  &--valid {
    .select-country {
      &__container {
        & > .input-custom__field-container__label {color : #00897b}
        &__select__container__trigger {border-color : #00897b}
        }
      }
    }
  }


.countryOptions {
  //position         : fixed;
  //width            : 100%;
  //height           : 100%;
  padding          : 1rem 0;
  top              : 0;
  left             : 0;
  overflow         : hidden;
  transition       : $transitionDefault;
  //transform        : scale(0.4);
  border-radius    : 1.7rem;
  background-color : $colorBackgroundContent;
  //opacity          : 0;
  //visibility       : hidden;
  z-index          : 110;

  &--show {
    transform  : scale(1);
    opacity    : 1;
    visibility : visible;
    }

  &__search {
    //padding : 0 4.4rem;

    @media only screen and (max-width : 430px) {padding : 0 1rem}
    }

  &__content {
    display             : flex;
    flex-flow           : column;
    max-height          : calc(100% - 11.4rem);
    margin-right        : 0.5rem;
    overflow            : auto;
    overscroll-behavior : none;

    @media only screen and (max-width : 430px) {margin-right : 0}

    &__option {
      justify-content : flex-start;
      width           : 100%;
      margin          : 0;
      border-radius   : 0;

      @media only screen and (max-width : 430px) {padding : 0 2rem 0 3.1rem}

      .ripple-button {
        padding : 0 20px;

        &__title {
          flex-grow      : 1;
          padding-left   : 0;
          overflow       : hidden;
          text-align     : left;
          white-space    : nowrap;
          text-transform : initial;
          text-overflow  : ellipsis;
          }
        &__icon {
          flex-shrink : 0;
          width       : 1.3rem;
          margin-left : 0.5rem;
          padding     : 0;
          font-size   : 1.3rem;
          }
        }
      }
    }
  }