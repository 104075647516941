.draft-code-layout {
  display : flex;

  &__languages,
  &__editor,
  &__preview {
    height        : calc(100vh - 285px);
    margin-bottom : 0;
    overflow      : auto;
    }

  &__editor,
  &__preview {
    width         : 100%;
    max-width     : 820px;
    margin-bottom : 0;
    margin-left   : $marginContainerInner;
    }

  &__languages {
    flex-grow     : 1;
    min-width     : 150px;
    padding       : 15px 8px;
    border-radius : $borderRadiusContainerInnerEdge $borderRadiusContainerInner $borderRadiusContainerInner $borderRadiusContainerInnerEdge !important;
    }

  &__editor {
    border-radius : $borderRadiusContainerInner !important;
    }

  &__preview {
    display   : flex;
    flex-flow : column;

    &__settings {
      flex-grow     : 0;
      flex-shrink   : 0;
      border-radius : $borderRadiusContainerInner $borderRadiusContainerInnerEdge $borderRadiusContainerInner $borderRadiusContainerInner !important;
      }

    &__code {
      flex-grow     : 1;
      margin-bottom : 0;
      border-radius : $borderRadiusContainerInner $borderRadiusContainerInner $borderRadiusContainerInnerEdge $borderRadiusContainerInner !important;
      }
    }
  }

.draft-code-select-buttons {
  display   : flex;
  //flex-flow : row wrap;
  flex-flow : column;
  //padding-top : 10px;

  & .ripple-button-container {
    height        : 3rem;
    margin-right  : 5px;
    margin-bottom : 5px;

    .ripple-button {

      &__title {
        padding-left : 10px;
        font-family  : $fontFamilySecondary;
        font-size    : $fontSizeSecondarySmall;
        font-weight  : $fontWeightSecondaryBold;
        line-height  : $lineHeightSecondary;
        text-align   : left;
        }

      &__icon {
        display         : flex;
        justify-content : center;
        align-items     : center;
        width           : 20px;
        padding         : 0;
        font-size       : 1.3rem;
        opacity         : 0.4;
        }
      }
    }

  &__selected {
    .ripple-button {
      &:before {
        background-color : var(--buttonColorBasic);
        opacity          : 0.2;
        }
      &__icon {
        opacity : 1 !important;
        }
      }
    }
  }

.draft-code-editor .content-editor__code-block,
.draft-code-preview pre.shiki {
  margin  : 0 !important;
  padding : 15px $paddingContainerOuter $paddingContainerOuter 20px !important;
  }

.draft-code-editor .public-DraftStyleDefault-block,
.draft-code-preview pre.shiki > code {
  padding : 0 calc(20px - $paddingContainerOuter) 10px 0 !important;
  }

.draft-code-editor {

  .public-DraftStyleDefault-block {
    height : calc(100vh - 410px);
    }

  .content-editor__area > .DraftEditor-root .public-DraftEditor-content {
    //min-height : 0;
    }
  }

.draft-code-preview {
  pre.shiki > code {
    height : calc(100vh - 484px);
    }
  }

.draft-code-subtitle {
  padding-bottom : 5px;
  font-family    : $fontFamilySecondary;
  font-size      : $fontSizeSecondarySmall;
  font-weight    : $fontWeightSecondaryBold;
  line-height    : $lineHeightSecondarySmall;
  color          : $colorFontLight;
  }

.draft-code-switch {
  padding : 15px 0 0;
  }

.draft-code-bottom {
  display         : flex;
  justify-content : flex-end;
  align-items     : center;
  margin          : 0;
  padding         : $paddingContainerOuter 0 0;

  & .ripple-button {
    padding : 0 20px;

    &__title {
      font-family : $fontFamilySecondary;
      font-size   : $fontSizeSecondary;
      font-weight : $fontWeightSecondaryBold;
      line-height : $lineHeightSecondarySmall;
      }

    &__icon {
      font-size : 1.7rem;
      }
    }
  }