@import "/src/styles/variables";

.header {
  display          : block;
  position         : fixed;
  width            : 100%;
  min-width        : 0;
  max-width        : 100%;
  //padding          : 0 20px;
  height           : 70px;
  top              : var(--header-top, 0);
  left             : 0;
  transition       : $transitionDefault, width 0s ease, filter calc(var(--transitionDurationDefault) / 2) $transitionTimingFunctionDefault, background-color 0s ease, border-bottom 0s ease;
  font-family      : $fontFamilyPrimary;
  border-bottom    : 1px solid $colorBackgroundBasic;
  background-color : $colorBackgroundContent;
  z-index          : 998;

  [data-device=mobile] & {
    height  : 60px;
    padding : 0;
    }
  }