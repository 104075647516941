@import "../PaymentMethodSelect";
@import "components/paymentType/PaymentTypeSelect";

.deployment-payment-modal {
  .modal-layout__layer {
    width     : 100%;
    max-width : 80rem;
    padding   : 10px;
    }
  }

.deploy-payment {
  position         : relative;
  padding-right    : calc(var(--paddingContainerOuter) / 2);
  padding-left     : $paddingContainerOuter;
  border-radius    : $borderRadiusContainerOuter;
  background-color : $colorBackgroundContent;

  &__debug-trigger {
    position : absolute;
    top      : $paddingContainerOuter;
    right    : $paddingContainerOuter;
    }

  &__header {
    display       : flex;
    align-items   : center;
    padding       : $paddingContainerOuter 0 $paddingContainerOuter;
    border-radius : $borderRadiusContainerOuter $borderRadiusContainerOuter 0 0;

    &__nav {}

    &__title {
      padding-left  : 4px;
      overflow      : hidden;
      font-size     : 1.5rem;
      font-weight   : 600;
      line-height   : 1.3;
      white-space   : nowrap;
      text-overflow : ellipsis;
      color         : $colorFontTitle;
      }
    }

  &__footer {
    display         : flex;
    justify-content : flex-end;
    padding         : 20px 24px 20px 20px;

    &__button {
      .ripple-button__title {
        //font-family: $fontFamilySecondary;
        font-size      : 1.5rem;
        font-weight    : $fontWeightPrimaryRegular;
        text-transform : initial;
        }
      }
    }

  &__container {

    &__content {
      max-height    : calc(100vh - 30rem);
      padding-right : calc(var(--paddingContainerOuter) / 2);
      overflow      : auto;
      border-radius : $borderRadiusContainerInnerEdge;
      }
    }
  }