.layout-content {
  display   : flex;
  flex-flow : column;
  flex-grow : 1;
  width     : 100%;
  height    : 100%;
  padding   : 0;

  &__item {
    display     : flex;
    flex-flow   : column;
    align-items : center;

    &__container-fixed-width {
      width     : 100%;
      max-width : 1200px;
      padding   : 20px;

      &__content-header {
        margin        : 0 0 20px;
        padding       : 0 0 20px 20px;
        border-bottom : 1px solid #eee;
        }
      }

    &__container-full-width {
      min-width : 100%;
      padding   : 20px 20px;
      }


    &__breadcrumb {
      padding : 0 15px 20px;
      }
    }
  }

.page-content {
  display          : flex;
  flex-flow        : column;
  flex-grow        : 1;
  align-items      : center;
  width            : 100%;
  height           : 100%;
  padding          : 0;
  background-color : $colorBackgroundBasicLight;

  &__block {
    display     : flex;
    flex-flow   : column;
    align-items : center;
    width       : 100%;

    &__container-centered {
      display     : flex;
      flex-flow   : column;
      align-items : center;
      width       : 100%;
      max-width   : 120rem;
      }

    &__container-stretched {
      display     : flex;
      flex-flow   : column;
      align-items : center;
      width       : 100%;
      }
    }
  }