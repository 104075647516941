.add-draft-link {

  &__content {
	//padding   : 1rem 2rem;

	&__preview {
	  position        : relative;
	  display         : flex;
	  justify-content : center;
	  align-items     : center;
	  margin-top      : 2rem;
	  padding         : 1.5rem 0 1.5rem;
	  font-size       : 1.4rem;
	  font-weight     : 600;
	  line-height     : 1.6;
	  text-decoration : none;

	  [data-theme=light] & {
		//border-top    : 0.1rem solid $background-lvl-5;
		//border-bottom : 0.1rem solid $background-lvl-5;
		}
	  [data-theme=dark] & {
		//border-top    : 0.1rem solid $background-lvl-1;
		//border-bottom : 0.1rem solid $background-lvl-1;
		}

	  &::before {
		position         : absolute;
		margin-right     : auto;
		margin-left      : auto;
		padding          : 0 1rem;
		top              : -1.1rem;
		//background-color : $background-lvl-0;
		font-size        : 1.3rem;
		font-weight      : 700;
		color            : $colorFontCommon;
		content          : "Preview";
		text-transform   : uppercase;

		[data-theme=light] & {
		  //background-color : $background-lvl-0;
		  //color            : #c5c5c5;
		  }
		[data-theme=dark] & {
		  //background-color : $background-lvl-9;
		  //color            : $background-lvl-1;
		  }
		}

	  &__external-icon {
		margin-left : 0.4rem;
		font-size   : 1.2rem;
		}
	  }

	&__switch {
	  margin-bottom : 1rem;
	  }

	> input {
	  height        : 3.6rem;
	  margin-bottom : 2rem;
	  font-size     : 1.4rem;
	  }
	}

  &__bottom {
	display         : flex;
	justify-content : flex-end;
	align-items     : center;
	padding         : 2.5rem 1rem 1rem;

	.simple-button {
	  margin : 0;
	  }
	}
  }