.description-handler-editor-layout {
  width      : 100%;
  max-width  : 100vw;
  height     : 100%;
  max-height : 100vh;
  overflow   : hidden;

  .modal-layout__layer__content {height : 100%;}
  }

.description-handler-editor {
  display          : flex;
  flex-flow        : column;
  height           : 100%;
  background-color : $colorBackgroundBasicLight;

  &__header {
    display          : flex;
    justify-content  : flex-start;
    height           : 4rem;
    overflow         : hidden;
    transition       : $transitionDefault;
    background-color : $colorBackgroundContent;

    &__back {
      .ripple-button__title {text-transform : initial;}
      }
    }

  &__content {
    flex-grow : 1;

    .content-editor {
      position : relative;
      height   : 100%;

      .draft-control-panel {
        position      : sticky;
        top           : 0;
        border-bottom : 1px solid $colorBackgroundBasic;
        border-radius : 0;
        z-index       : 99;
        }

      %draft-container {
        display          : flex;
        flex-flow        : column;
        justify-content  : center;
        align-items      : center;
        height           : auto;
        min-height       : 100%;
        padding          : 20px 5px 40px;
        background-color : $colorBackgroundBasicLight;
        }

      %draft-content {
        flex-grow        : 1;
        width            : 100%;
        max-width        : 900px;
        padding          : 40px 60px;
        background-color : $colorBackgroundContent;
        }

      .content-editor__area {
        display  : flex;
        height   : calc(100vh - 4rem - var(--control-panel-height, 51px));
        overflow : auto;

        .DraftEditor-root,
        .content-editor__area__preview {min-height : 100%;}

        .public-DraftEditor-content,
        .content-editor__area__preview__content {
          @extend %draft-container;

          & > div,
          & > .content-editor {
            @extend %draft-content;
            }
          }

        &--split {
          .public-DraftEditor-content {align-items : flex-end;}
          .content-editor__area__preview__content {align-items : flex-start;}
          }
        }
      }
    }

  &__scroll-top {
    position   : fixed;
    right      : 40px;
    bottom     : 40px;
    transition : $transitionDefault;
    transform  : scale(0.6);
    opacity    : 0;
    visibility : hidden;
    z-index    : 100;

    &--show {
      transform  : scale(1);
      opacity    : 1;
      visibility : visible;
      }
    }

  &--hide-header {
    .description-handler-editor__header {height : 0;}
    .content-editor {
      .content-editor__area {
        min-height : calc(100vh - var(--control-panel-height, 51px));
        max-height : calc(100vh - var(--control-panel-height, 51px));
        }
      }
    }
  }