.image-draft-block {
	position: relative;
	display: flex;
	justify-content: var(--align, center);
	align-items: center;

	&--deprecated {
		padding: 1rem;
		border: 0.4rem dashed red;

		&::after {
			position: absolute;
			padding: 0.5rem 1rem;
			top: 1rem;
			right: 1rem;
			background-color: red;
			border-radius: 0.5rem;
			font-weight: 700;
			color: #fff;
			content: 'Deprecated base64';
			}
		}

	&:hover &__edit-button {
		transform: scale(1);
		transform-origin: right top;
		opacity: 1;
		visibility: visible;
		}

	&__container {
		display: flex;
		flex-flow: column;

		&__image-container {
			max-height: var(--max-image-height, 70rem);
			margin: var(--container-margin, 0);
			background-color: #a0aeb8;

			&__img {
				display: flex;
				max-width: 100%;
				max-height: inherit;
				margin: var(--image-margin, 0);
				border: var(--image-border, 0rem);
				}
			}

		&__caption {
			display: flex;
			justify-content: var(--align, center);
			align-items: center;
			margin: 0;
			padding: 0 1rem;
			font-size: 1.4rem;
			font-weight: 400;
			font-style: italic;
			line-height: 1.4;
			color: #747474;
			}
		}

	&__edit-button {
		position: absolute;
		display: flex;
		align-items: center;
		padding: 0 1rem;
		top: -1rem;
		right: -1rem;
		background-color: #2887ef;
		border-radius: 0.3rem;
		cursor: pointer;
		user-select: none;
		box-shadow: -0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.2), -0.1rem -0.1rem 1.2rem rgba(0, 0, 0, 0.12);
		transition: $transitionDefault;
		transform: scale(0.8);
		opacity: 0;
		visibility: hidden;

		&__title {
			margin: 0;
			font-family: $fontFamilyPrimary;
			font-size: 1.5rem;
			font-weight: 700;
			color: #fff;
			text-transform: uppercase;

			& > span {
				font-size: 1.4rem;
				font-weight: 600;
				text-transform: initial;
				}
			}

		&__icon {
			padding-left: 0.8rem;
			font-size: 1.4rem;
			color: #fff;
			}
		}

	&__resolution {
		font-family: $fontFamilySecondary;
		font-size: $fontSizeSecondaryTiny;
		font-weight: $fontWeightSecondaryBold;
		position: absolute;
		color: white;
		left: 0;
		top: 0;
		padding: 3px 7px;
		background-color: black;
		}
	}