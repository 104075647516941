@import 'src/styles/variables';

.dev-controls {
  display        : flex;
  position       : fixed;
  flex-flow      : column;
  padding-bottom : 0;
  padding-left   : 1rem;
  bottom         : 0;
  left           : 0;
  z-index        : 990;

  &__item {
    display  : flex;
    position : relative;
    padding  : 0 0 1rem;

    @keyframes trigger-appear {
      from {
        transform : scale(0.3);
        opacity   : 0;
        }
      to {
        transform : scale(1);
        opacity   : 1;
        }
      }

    &__trigger {
      width            : 4rem;
      height           : 4rem;
      transition       : .5s ease 0s, opacity .2s ease .1s;
      transform        : scale(1);
      transform-origin : center center;
      animation        : trigger-appear $transitionDurationDefault forwards;
      border-radius    : 50%;
      z-index          : 990;

      :global .ripple-button {
        transition : 0s ease, background-color $transitionDefault;
        }
      }

    &__content {
      position            : absolute;
      width               : 35rem;
      max-width           : calc(100vw - 7rem);
      max-height          : calc(100vh - 12rem);
      bottom              : 1rem;
      left                : 5.4rem;
      overflow            : auto;
      transition          : $transitionDefault 150ms;
      transform           : scale(0.7);
      transform-origin    : left bottom;
      border-radius       : 1.5rem;
      background-color    : $colorBackgroundTop;
      opacity             : 0;
      visibility          : hidden;
      box-shadow          : 0 0 0.6rem rgba(0, 0, 0, 0.2), 0.2rem 0.2rem 2rem rgba(0, 0, 0, 0.1);
      z-index             : 991;
      overscroll-behavior : none;

      & > * {
        transition : $transitionDefault;
        opacity    : 0;
        visibility : hidden;
        }

      &--show {
        transition : $transitionDefault;
        transform  : scale(1);
        opacity    : 1;
        visibility : visible;

        & > * {
          transition : $transitionDefault 150ms;
          opacity    : 1;
          visibility : visible;
          }
        }
      }
    }
  }