@import "src/styles/variables";

.logo {
  display     : flex;
  align-items : center;
  height      : 100%;

  &:focus {outline : none;}
  &:focus-visible {outline : 1px solid $colorFontInfo;}

  &__image {
    display         : flex;
    justify-content : center;
    align-items     : center;
    width           : 32px;
    height          : 32px;
    margin-bottom   : 0px;

    [data-device=mobile] & {margin-bottom : 0}
    }

  &__brand {
    height      : 16px;
    margin-top  : 3px;
    margin-left : 6px;

    @media (max-width : 1070px) and (min-width : 830px) {display : none}
    @media only screen and (max-width : 330px) {display : none}
    }
  }