.custom-modal {
  display          : flex;
  position         : fixed;
  justify-content  : center;
  align-items      : center;
  width            : 100%;
  height           : 100%;
  padding          : 20px;
  top              : 0;
  left             : 0;
  transition       : $transitionDefault;
  background-color : rgba(0, 0, 0, 0.6);
  opacity          : 1;
  visibility       : visible;
  z-index          : 999;

  &__container {
    max-width           : 1200px;
    max-height          : calc(100vh - 7rem);
    margin-left         : 0;
    padding             : 20px;
    overflow            : auto;
    transition          : $transitionDefault;
    transition-delay    : .2s;
    animation           : modal-open 100ms forwards;
    border-radius       : 6px;
    background-color    : $colorBackgroundContent;
    opacity             : 1;
    visibility          : visible;
    box-shadow          : 0.1rem 0.2rem 1rem rgba(0, 0, 0, 0.2);
    overscroll-behavior : none;

    > div {
      width : 100%;
      }

    &__title {
      margin      : 0;
      padding     : 0 1rem;
      font-size   : 1.8rem;
      font-weight : 600;
      }

    &__body,
    &__header,
    &__footer {
      animation : modal-content-appear 300ms forwards;
      }
    }
  }

.custom-modal-closed {
  opacity    : 0;
  visibility : hidden;

  .custom-modal__container {
    opacity    : 0;
    visibility : hidden;
    }
  }


@keyframes modal-open {
  from {transform : scale(0.4)}
  to {transform : scale(1)}
  }

@keyframes modal-content-appear {
  from {opacity : 0}
  50% {opacity : 0}
  to {opacity : 1}
  }