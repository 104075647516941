//@import "../../../sass/variables";

.page-loading {
  display         : flex;
  justify-content : center;
  align-items     : center;
  min-width       : 100%;
  min-height      : 60vh;

  &__container {
	position : relative;
	width    : 60px;
	height   : 60px;
	//margin   : 100px auto;

	&__double-bounce-1,
	&__double-bounce-2 {
	  position      : absolute;
	  top           : 0;
	  left          : 0;
	  opacity       : 0.6;
	  border-radius : 50%;
	  animation     : sk-bounce 2.0s infinite ease;
	  }

	&__double-bounce-1 {
	  width            : 40px;
	  height           : 40px;
	  margin-top       : 10px;
	  margin-left      : 10px;
	  background-color : #dadada;
	  }

	&__double-bounce-2 {
	  width            : 60px;
	  height           : 60px;
	  background-color : #4ea9f4;
	  animation-delay  : -1.0s;
	  }
	}
  }

.component-loading {
  min-width  : min-content;
  min-height : min-content;

  .page-loading__container {
	width  : 45px;
	height : 45px;

	&__double-bounce-1 {
	  width: 25px;
	  height: 25px;
	  }

	&__double-bounce-2 {
	  width: 45px;
	  height: 45px;
	  }
	}
  }

@keyframes sk-bounce {
  0%,
  100% {transform : scale(0.0);}
  50% {transform : scale(1.0);}
  }


.loading-component {
  display         : flex;
  flex-flow       : row wrap;
  justify-content : center;
  align-items     : center;
  min-height      : 300px;
  padding         : 20px;

  > i {
	margin-right : 5px;
	}

  &__loading-msg {
	margin    : 20px 15px;
	font-size : 22px;
	}

  &__cover {
	display : none;
	}
  }

.loading-component--blocked-interface {
  position  : fixed;
  flex-flow : column nowrap;
  width     : 100vw;
  height    : 100vh;
  top       : 0;
  left      : 0;
  z-index   : 998;


  .page-loading {z-index : 998;}

  .page-loading__container__double-bounce-1,
  .page-loading__container__double-bounce-2 {
	opacity : 1;
	}

  .loading-component {
	&__cover {
	  position         : fixed;
	  display          : block;
	  width            : 100vw;
	  height           : 100vh;
	  top              : 0;
	  left             : 0;
	  background-color : #fff;
	  z-index          : 998;
	  opacity          : 0.8;
	  }

	&__loading-msg {
	  font-family : $fontFamilyPrimary;
	  font-weight : 600;
	  text-align  : center;
	  z-index     : 998;
	  }
	}
  }

