@import '/src/styles/variables';

.footer {
  display          : flex;
  flex-flow        : column;
  justify-content  : center;
  align-items      : center;
  padding          : 30px 0 10px;
  border-top       : 0.1rem solid $colorBackgroundBasic;
  //background-color : #9694bb17;
  background-color : $colorBackgroundContent;

  &__separator {border-top : 0.1rem solid $colorBackgroundBasic;}
  &__social {padding-bottom : 30px;}
  &__links {padding-bottom : 50px;}
  &__attributions {padding : 10px 0 0;}
  &__project {padding : 20px 0 0;}
  }

.socials {
  display         : flex;
  flex-flow       : row wrap;
  justify-content : center;
  align-items     : center;
  padding         : 0 20px;

  @media only screen and (max-width : 490px) {max-width : 30rem;}

  &__link {
    display          : flex;
    justify-content  : center;
    align-items      : center;
    width            : 4.5rem;
    height           : 4.5rem;
    margin           : 3px;
    padding          : 2px 0;
    transition       : $transitionDefault;
    border-radius    : 50%;
    outline          : 1px solid var(--social-color);
    outline-offset   : -1px;
    background-color : $colorBackgroundContent;
    [data-theme=dark] & {outline-color : var(--social-color-dark, var(--social-color));}

    &:focus-visible {
      transition     : $transitionDefault, outline-width 0s ease, outline-offset 0s ease;
      outline-width  : 4px;
      outline-offset : -2px;
      }

    &:hover {
      text-decoration  : none;
      background-color : var(--social-color);
      [data-theme=dark] & {background-color : var(--social-color-dark, var(--social-color));}
      }

    &:hover &__logo {
      color : $colorBackgroundContent;
      [data-theme=dark] & {color : $colorBackgroundContent;}
      }

    &__logo {
      display         : flex;
      justify-content : center;
      align-items     : center;
      margin          : var(--social-margin);
      transition      : $transitionDefault;
      font-size       : 2.2rem;
      color           : var(--social-color);
      [data-theme=dark] & {color : var(--social-color-dark, var(--social-color));}

      > svg {
        width  : 2.2rem;
        height : 2.2rem;
        }
      }
    }

  &__github {
    background-color : var(--social-color);
    [data-theme=dark] & {background-color : var(--social-color-dark, var(--social-color));}

    &:hover {
      background-color : $colorBackgroundContent;
      [data-theme=dark] & {background-color : $colorBackgroundContent;}
      }

    &:hover .socials__link__logo {
      color : var(--social-color);
      [data-theme=dark] & {color : var(--social-color-dark, var(--social-color));}
      }

    .socials__link__logo {
      color : $colorBackgroundContent;
      [data-theme=dark] & {color : $colorBackgroundContent;}
      }
    }
  }

.links {
  display         : flex;
  justify-content : center;
  align-items     : flex-start;
  width           : 100%;

  @media only screen and (max-width : 705px) {
    flex-flow   : column;
    align-items : center;
    max-width   : 28rem;
    }

  &__block {
    display   : flex;
    flex-flow : column;
    min-width : 230px;
    padding   : 0 10px;

    @media only screen and (max-width : 705px) {
      min-width      : 0;
      max-width      : 100%;
      padding-bottom : 40px;
      }

    &__title {
      margin         : 0;
      padding        : 10px 0 20px;
      font-family    : $fontFamilyPrimary;
      font-size      : 1.3rem;
      font-weight    : 700;
      text-align     : center;
      text-transform : uppercase;
      color          : $colorFontCommon;


      @media only screen and (max-width : 490px) {
        font-family : $fontFamilySecondary;
        font-size   : 2rem;
        font-weight : $fontWeightSecondaryBoldExtra;
        }
      }

    &__link {
      max-width     : 100%;
      padding-top   : 5px;
      overflow      : hidden;
      font-family   : $fontFamilyPrimary;
      font-size     : 1.5rem;
      font-weight   : 600;
      text-align    : center;
      white-space   : nowrap;
      text-overflow : ellipsis;

      @media only screen and (max-width : 705px) {
        display       : block;
        max-width     : 100%;
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;
        }

      @media only screen and (max-width : 490px) {
        font-family : $fontFamilySecondary;
        font-size   : 2rem;
        font-weight : $fontWeightSecondaryBold;
        }
      }
    }
  }

.attributions {
  font-size  : 1.3rem;
  text-align : center;
  }

.project {
  display         : flex;
  flex-flow       : row wrap;
  justify-content : center;
  align-items     : center;
  text-align      : center;

  > p {
    padding   : 0 2px;
    font-size : 1.3rem;
    }
  }