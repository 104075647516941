.draft-video-switch {
  padding-bottom : 7px;
  }

.draft-video-aspect-buttons {
  display         : flex;
  justify-content : center;
  align-items     : center;
  padding         : 1rem 0 0;
  }

.draft-video-bottom {
  display         : flex;
  justify-content : flex-end;
  padding         : 15px 20px;
  margin          : 0;
  }

.draft-video-preview {
  position    : relative;
  width       : 100%;
  padding-top : var(--aspect-ratio, 56.25%);
  transition  : $transitionDefault;

  &__overlay {
    position   : absolute;
    top        : 0;
    right      : 0;
    bottom     : 0;
    left       : 0;
    transition : $transitionDefault;

    &__preview {
      width      : 100%;
      height     : 100%;
      transition : $transitionDefault;
      }
    }

  &--thin {
    .add-draft-video__preview-container {
      &__overlay {
        border : 0.1rem solid #888888;
        }
      }
    }

  &--full {
    width            : calc(100% - 2rem);
    margin           : 0 2rem 2rem 0;
    padding-top      : calc(var(--aspect-ratio, 56.25%) - 1rem);
    background-color : #2a3a45;

    .add-draft-video__preview-container {
      &__overlay {
        margin : 2rem -2rem -2rem 2rem;
        border : 0.1rem solid #2a3a45;
        &__preview {
          }
        }
      }
    }
  }