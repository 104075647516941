.custom-modal {
  display          : flex;
  position         : fixed;
  justify-content  : center;
  align-items      : center;
  width            : 100%;
  height           : 100%;
  padding          : 90px 20px 20px;
  top              : 0;
  left             : 0;
  transition       : $transitionDefault;
  background-color : rgba(0, 0, 0, 0.5);
  opacity          : 1;
  visibility       : visible;
  z-index          : 999;

  &__container {
    max-width           : 1200px;
    max-height          : calc(100vh - 50px);
    margin-left         : 0;
    padding             : 20px;
    overflow            : auto;
    transition          : $transitionDefault 0.2s;
    transition-delay    : .2s;
    transform           : scale(1);
    animation           : modal-open 100ms forwards;
    border              : 1px solid #ccc;
    border-radius       : 0.3rem;
    background-color    : #fff;
    opacity             : 1;
    visibility          : visible;
    box-shadow          : 1px 2px 10px rgba(0, 0, 0, 0.2);
    overscroll-behavior : none;

    > div {
      width : 100%;
      }

    .custom-modal-body,
    .custom-modal-header,
    .custom-modal-footer {
      animation : modal-content-appear 300ms forwards;
      }
    }

  //::-webkit-scrollbar {width : 0.4rem}
  //
  //::-webkit-scrollbar-track {
  //  margin        : 0.2rem 0;
  //  border-radius : 0.3rem;
  //  background    : #ddd;
  //  }
  //
  //::-webkit-scrollbar-thumb {
  //  border-radius : 0.2rem;
  //  background    : #9a9a9a;
  //  }
  //
  //::-webkit-scrollbar-thumb:hover {background : #848484}
  }

.custom-modal-closed {
  opacity    : 0;
  visibility : hidden;

  .custom-modal__container {
    transform  : scale(0.6);
    opacity    : 0;
    visibility : hidden;
    }
  }

@keyframes modal-open {
  from {transform : scale(0.4)}
  to {transform : scale(1)}
  }

@keyframes modal-content-appear {
  from {opacity : 0}
  50% {opacity : 0}
  to {opacity : 1}
  }