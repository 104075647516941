.draft-code-block {
  display         : flex;
  position        : relative;
  justify-content : center;
  align-items     : center;
  width           : 100%;
  min-width       : 0;
  max-width       : 100%;

  &__container {
    width     : 100%;
    max-width : 100%;
    }

  &__additional {
    display     : flex;
    position    : absolute;
    align-items : center;
    top         : 10px;
    right       : 4px;

    &__language {
      padding          : 5px 10px;
      cursor           : default;
      user-select      : none;
      transition       : $transitionDefault;
      font-family      : $fontFamilySecondary;
      font-size        : 011px;
      font-weight      : $fontWeightSecondaryBoldExtra;
      line-height      : $lineHeightSecondaryTiny;
      color            : $colorAccentContrast;
      border-radius    : 15px;
      background-color : $colorAccentSoft;
      opacity          : 0.5;

      &:hover {
        opacity : 1;
        color            : $colorAccentBasic;
        }
      }

    &__button {
      display          : flex;
      justify-content  : center;
      align-items      : center;
      width            : 36px;
      height           : 36px;
      margin-left      : 4px;
      transition       : $transitionDefault;
      color            : $colorAccentBasic;
      border           : none;
      border-radius    : 50%;
      background-color : transparent;
      opacity          : 0.8;

      &:hover {
        background-color : $colorAccentSoft;
        opacity          : 1;
        }

      &:active {
        background-color : $colorAccentBasic;

        & > i {
          color : $colorBackgroundContent;
          }
        }

      & > i {
        color : $colorAccentBasic;
        }
      }
    }
  }