.checkbox-custom {
  display       : flex;
  margin-top    : 15px;
  padding-right : 30px;
  padding-left  : 5px;
  list-style    : none;
  cursor        : pointer;

  @media only screen and (max-width : 420px) {padding-right : 7px}

  > input {
    position : absolute;
    width    : 0;
    height   : 0;
    cursor   : pointer;
    opacity  : 0;
    }

  &__container {
    display : flex;

    &__icon {
      position       : relative;
      padding-right  : 30px;
      padding-bottom : 6px;
      font-size      : 22px;
      color          : $colorFontLight;

      &__icon-checked,
      &__icon-empty {
        position    : absolute;
        transition  : $transitionDefault;
        line-height : 18px;
        }

      &__icon-checked {
        opacity    : 0;
        visibility : hidden;
        }

      &__icon-empty {
        opacity    : 1;
        visibility : visible;
        }
      }

    &__content {
      padding-top : 6px;

      &__title {
        display       : flex;
        align-items   : flex-start;
        margin-top    : -6px;
        margin-bottom : 10px;

        &__arrow {
          display         : flex;
          justify-content : center;
          align-items     : center;
          width           : 16px;
          height          : 18px;
          margin-right    : 5px;
          margin-left     : -5px;
          transition      : $transitionDefault;
          font-size       : 10px;
          color           : $colorFontWarning;

          &:hover {color : #ffc416}
          }

        &__locker {
          display         : flex;
          justify-content : center;
          align-items     : center;
          align-self      : center;
          width           : 0;
          margin          : 0;
          transition      : $transitionDefault;
          font-size       : 14px;
          color           : $colorFontWarning;
          opacity         : 0;
          visibility      : hidden;
          }

        &__main {
          margin      : 0;
          transition  : $transitionDefault;
          font-family : $fontFamilyPrimary;
          font-size   : 15px;
          font-weight : 700;
          color       : $colorFontCommon;
          }

        &__explain {

          &__link {
            display  : block;
            position : relative;

            &:hover {
              text-decoration : none;
              outline         : none;
              }

            &__external {
              position         : absolute;
              padding          : 0.2rem;
              top              : -0.4rem;
              right            : -0.8rem;
              font-size        : 0.8rem;
              border-radius    : 50%;
              background-color : $colorBackgroundContent;
              z-index          : 1;
              }
            }

          .checkbox-explain-button {
            width         : 1.6rem;
            height        : 1.6rem;
            margin        : 0 0 0 0.5rem;
            border-radius : 0.7rem;
            }
          }

        &__suggest {
          margin           : 0 10px 0;
          padding          : 4px 3px 3px;
          font-size        : 10px;
          font-weight      : 500;
          line-height      : 9px;
          text-transform   : uppercase;
          color            : $colorFontWarning;
          border           : 1px solid $colorFontWarning;
          border-radius    : 3px;
          background-color : $colorBackgroundContent;
          }
        }

      &__description {
        position      : relative;
        margin-bottom : 0.5rem;
        margin-left   : 0.3rem;
        padding-left  : 15px;
        transition    : opacity .2s ease .3s, visibility .2s ease .3s, max-height .3s ease;
        font-size     : 15px;
        font-weight   : 300;
        line-height   : 25px;
        opacity       : 1;
        visibility    : visible;

        &::before {
          // left line of checkbox description
          // why "::before"? line should have different height than parent element
          position    : absolute;
          content     : "";
          width       : 1px;
          height      : calc(100% - 0.5rem);
          left        : 0;
          border-left : 0.1rem solid $colorFontWarning;
          }

        &__before {
          margin-bottom : 0.5rem;
          }

        &__text {
          margin      : 0;
          transition  : $transitionDefault;
          font-family : $fontFamilyPrimary;
          font-size   : 14px;
          font-weight : 400;
          line-height : 21px;
          text-align  : justify;
          color       : $colorFontLight;
          }

        &__link-container {
          margin-top    : 4px;
          margin-bottom : 0;
          transition    : $transitionDefault;
          font-size     : 14px;
          font-weight   : 300;

          &__link {
            margin-left : 7px;
            transition  : .2s linear;

            @media only screen and (max-width : 445px) {
              display        : inline-block;
              max-width      : 180px;
              overflow       : hidden;
              vertical-align : top;
              white-space    : nowrap;
              text-overflow  : ellipsis;
              }
            }
          }
        }
      }
    }
  }

.checkbox-item-selected {
  .checkbox-custom {
    &__container {
      &__icon {
        &__icon-checked,
        &__icon-empty {color : $colorFontCommon}

        &__icon-checked {
          opacity    : 1;
          visibility : visible;
          }

        &__icon-empty {
          opacity    : 0;
          visibility : hidden;
          }
        }
      }
    }
  }

.hide-checkbox-description {
  .checkbox-custom__container__content {
    &__title {
      &__arrow {transform : rotate(-90deg)}
      }

    &__description {
      max-height    : 0;
      margin-bottom : 0;
      transition    : opacity .1s ease, visibility .2s ease, max-height .3s ease .2s;
      opacity       : 0;
      visibility    : hidden;

      &__link-container {
        color : transparent;
        }
      }
    }
  }

.checkbox-item-locked {
  .checkbox-custom {
    &__container {
      &__icon {
        &__icon-checked {color : $colorFontLight}
        }

      &__content {
        &__title {
          &__locker {
            width      : 14px;
            margin     : 0 7px 0 0;
            opacity    : 1;
            visibility : visible;
            }

          &__main {color : $colorFontLight}
          }

        &__description {
          &__text {color : $colorFontLight}
          }
        }
      }
    }
  }