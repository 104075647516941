@import "src/styles/variables";

$folding-animation-timeout : $transitionDefault calc(var(--transitionDurationDefault) / 4);
$folding-animation         : calc(var(--transitionDurationDefault) / 2) $transitionTimingFunctionDefault;

.fc-container {
  max-height : var(--content-height, 4000px);
  transition : 0s ease, max-height $transitionDefault, opacity $folding-animation-timeout, visibility $folding-animation-timeout;
  opacity    : 1;
  visibility : visible;

  &--folded {
    max-height : 0;
    overflow   : hidden;
    transition : 0s ease, opacity $transitionDefault, visibility $transitionDefault, max-height $folding-animation-timeout;
    opacity    : 0;
    visibility : hidden;
    }
  }